<swe-card [header]="header" [headerDetail]="headerDetail" [hideHeaderOnDesktop]="hideHeader" *ngIf="timereport" [(open)]="open" (refreshChange)="load()" [focus]="true" [hasCollapse]="false" (saveChange)="save()" [hasSave]="timereport.Access>1" [rulelist]="['SweTimereportLevels, SweBookingUserProfile', 'SweBookingStatusTimereported', 'SweBookingStatusConfirmed', 'SweBookingStatusCompleted']">
  <span swe-rightcommand>

  </span>
  <!--Level-->
  <div class="mt-3"></div>
  <div class="mb-3 row" *ngIf="!loading&&!permissionService.permissions.HideLevels&&id==0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="bi bi-swe-fw bi-swe bi-diagram-3-fill" [attr.title]="languageService.getItem(14)"></i>
      </div>
      <div class="swe-col-content">
        <swe-levelsearch [(model)]="timereport.LevelId" [displayname]="timereport.LevelName" [onlyone]="true" [type]="'Booking'" [disabled]="timereport.LevelAccess<2" [markChanges]="true" [storeAtClient]="true"></swe-levelsearch>
        <span class="small" *ngIf="timereport.LevelPath&&timereport.LevelPath.length>0"><i>({{timereport.LevelPath}})</i></span>
      </div>
    </div>
  </div>
  <!--Status-->
  <div class="mb-3 row" *ngIf="!loading&&!permissionService.permissions.HideStatus">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-clock-fill': timereport.Status==40,'bi-check-lg': timereport.Status==50,'bi-lock-fill': timereport.Status==60}" [attr.title]="languageService.getItem(15)"></i>
      </div>
      <div class="swe-col-content">
        <!--Can't use swe-element cause of the disabled structure-->
        <select [attr.aria-label]="languageService.getItem(15)" class="form-control form-select" [(ngModel)]="timereport.Status" (ngModelChange)="manageCoreChange('Status', $event)" [ngClass]="{'bg-warning': coreChanged.Status.Changed}">
          <option value="0"></option>
          <option value="-1" [disabled]="managestatus(-1)">{{languageService.getItem(177)}}</option>
          <option *ngFor="let statusObj of generalService.timereportstatus" [value]="statusObj.Id" [disabled]="managestatus(statusObj.Id)">{{statusObj.Name}}</option>
        </select>
      </div>
    </div>
  </div>
  <!--DateTimes-->
  <div class="mb-{{timereport.StartAccess<2?0:3}} row">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': timereport.StartAccess<2}">
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-clock-fill': !abscent, 'bi-swe-bookingtype-abscent': abscent}" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
      </div>
      <div class="swe-col-content">
        <swe-datetime [(start)]="timereport.Start" [adjustEnd]="false" [(end)]="timereport.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true" [undoChanges]="isReload" *ngIf="timereport.StartAccess>1 || (id == 0 && (type != 3 || timereport.BookingType != 3))"></swe-datetime>
        <span style="vertical-align:sub;" *ngIf="timereport.StartAccess<2">{{timereport.DateTimeHeader}}</span>
      </div>
    </div>
  </div>
  <!--IncludedBreak-->
  <div class="mb-{{timereport.BreakAccess<2?0:3}} row" *ngIf="!loading && timereport.BreakAccess>0 && (type == 3 || timereport.BookingType != 3)&&permissionService.permissions.HasBreaks">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': timereport.BreakAccess<2}">
        <i class="bi bi-swe-fw bi-swe bi-cup-hot-fill" [attr.title]="languageService.getItem(785)"></i>
      </div>
      <div class="swe-col-content">
        <swe-breaklist [readonly]="timereport.BreakAccess<2" [(model)]="timereport.Breaks" (resetChange)="resetBreak()" [disabled]="timereport.BreakAccess<2" [disabledStart]="timereport.BreakStartAccess<2" [minStart]="timereport.Start" [maxStart]="timereport.End" [hasBreakInfo]="true" [reset]="true" [markChanges]="true"></swe-breaklist>
      </div>
    </div>
  </div>
  <!--TimeType (Start)-->
  <div class="row mb-{{timereport.TimeTypeAccess<2?0:3}}" *ngIf="!loading&&timetypes.length>0&&hasStartTime()&&timereport.TimeTypeAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': timereport.TimeTypeAccess<2}">
        <div>&nbsp;</div>
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-journal-plus': startTime()>0,'bi-journal-minus': startTime()<0}" [attr.title]="languageService.getItem(1115)"></i>
      </div>
      <div class="swe-col-content">
        <span class="small" *ngIf="hasDiff()" [attr.title]="languageService.getItem(1115)">{{timePart(1)}}</span>
        <swe-element [access]="timereport.TimeTypeAccess" [(model)]="timereport.StartTimeType" (modelChange)="relatedObjectChange()" [label]="languageService.getItem(1115)" [type]="'System.List'" [items]="starttimetypes" [disabled]="timereport.TimeTypeAccess<2" [optional]="true"></swe-element>
      </div>
    </div>
  </div>
  <!--TimeType-->
  <div class="row mb-{{timereport.TimeTypeAccess<2?0:3}}" *ngIf="!loading&&timetypes.length>0&&timereport.TimeTypeAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': timereport.TimeTypeAccess<2}">
        <div *ngIf="hasDiff()">&nbsp;</div>
        <i class="bi bi-swe-fw bi-swe bi-journal" [attr.title]="languageService.getItem(1121)"></i>
      </div>
      <div class="swe-col-content">
        <span class="small" *ngIf="hasDiff()">{{timePart(2)}}</span>
        <swe-element [access]="timereport.TimeTypeAccess" [(model)]="timereport.TimeType" (modelChange)="relatedObjectChange()" [label]="languageService.getItem(1115)" [type]="'System.List'" [items]="timetypes" [disabled]="timereport.TimeTypeAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--TimeType (End)-->
  <div class="row mb-{{timereport.TimeTypeAccess<2?0:3}}" *ngIf="!loading&&timetypes.length>0&&hasEndTime()&&timereport.TimeTypeAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': timereport.TimeTypeAccess<2}">
        <div>&nbsp;</div>
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-journal-plus': endTime()>0,'bi-journal-minus': endTime()<0}" [attr.title]="languageService.getItem(1116)"></i>
      </div>
      <div class="swe-col-content">
        <span class="small" *ngIf="hasDiff()" [attr.title]="languageService.getItem(1116)">{{timePart(3)}}</span>
        <swe-element [access]="timereport.TimeTypeAccess" [(model)]="timereport.EndTimeType" (modelChange)="relatedObjectChange()" [label]="languageService.getItem(1116)" [type]="'System.List'" [items]="endtimetypes" [disabled]="timereport.TimeTypeAccess<2" [optional]="true"></swe-element>
      </div>
    </div>
  </div>
  <!--Repeat-->
  <div *ngIf="!loading&&id==0">
    <swe-card [header]="languageService.getItem(251)" [color]="'swe'" [open]="false" [hasRefresh]="false">
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(254)" [statusLabel]="1" [(model)]="repeatdays" [items]="serieDayList" [container]="{isbit:true}"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(255)" [statusLabel]="1" [(model)]="repeatend"></swe-element>
    </swe-card>
  </div>
  <div *ngIf="!loading&&userid==0" class="row">
    <!--User-->
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="bi bi-swe-fw bi-swe bi-person-fill" [attr.title]="languageService.getItem(174)"></i>
      </div>
      <div class="swe-col-content mt-2">
        <span>{{user}}</span>
      </div>
    </div>
    <!--BookingType-->
    <div class="mt-4">
      <swe-element [model]="timereport.BookingTypeName" [label]="languageService.getItem(384)" [statusLabel]="1" [access]="1" [type]="'System.String'"></swe-element>
    </div>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="timereport.Profile" (modelChange)="profiledone()" [id]="id" [relatedObjectList]="relatedObjectList"></swe-profile>
  </div>
  <!--Remove ATK-->
  <div *ngIf="!loading&&id==0&&permissionService.permissions.RemoveATK">
    <swe-element [(model)]="timereport.RemoveATK" [statusLabel]="1" [label]="languageService.getItem(943)" [type]="'System.Boolean'"></swe-element>
  </div>
  <!--Admin-->
  <swe-card [header]="languageService.getItem(112)" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="!loading&&(timereport.ExternalAccess>0||timereport.SalaryAccess>0||timereport.InvoiceAccess>0)" [rulelist]="['SweTimereportSalary', 'SweExternalUserEmployments, SweTimereportInvoice']">
    <!--External Id-->
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="timereport.ExternalCategory" [label]="languageService.getItem(1065)" [items]="timereport.ExternalCategories" [type]="'System.List'" *ngIf="timereport.ExternalAccess>0"></swe-element>
    <swe-element [statusLabel]="1" [(model)]="timereport.ExternalCategoryName" [label]="languageService.getItem(1248)" [type]="'Internetplatsen.WarningInfo'" [access]="1" *ngIf="timereport.ExternalCategoryName&&timereport.ExternalCategoryName.length>0"></swe-element>
    <!--Salary Sent-->
    <swe-element [statusLabel]="1" [(model)]="timereport.SalarySent" [label]="languageService.getItem(1145)" [type]="'System.Boolean'" [disabled]="timereport.SalaryAccess<2" *ngIf="timereport.SalaryAccess>0"></swe-element>
    <!--Invoice Sent-->
    <swe-element [statusLabel]="1" [(model)]="timereport.InvoiceSent" [label]="languageService.getItem(1146)" [type]="'System.Boolean'" [disabled]="timereport.InvoiceAccess<2" *ngIf="timereport.InvoiceAccess>0"></swe-element>
    <!--Invoice Number-->
    <swe-element [statusLabel]="1" [(model)]="timereport.InvoiceNumber" [label]="languageService.getItem(1260)" [type]="'System.Int32'" [disabled]="true" *ngIf="timereport.InvoiceAccess>0"></swe-element>
    <!--OffDuty Factor-->
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1383)" [statusLabel]="1" [(model)]="timereport.OffDutyFactor" [disabled]="timereport.FactorAccess<2" *ngIf="timereport.FactorAccess>0"></swe-element>
    <!--Vacation Factor-->
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1384)" [statusLabel]="1" [(model)]="timereport.VacationFactor" [disabled]="timereport.FactorAccess<2" *ngIf="timereport.FactorAccess>0"></swe-element>
  </swe-card>
  <!--Buttons-->
  <div class="d-grid gap-2 mb-4" *ngIf="!loading">
    <button class="btn btn-primary" (click)="save()" *ngIf="timereport.Access>1" #sweSaveElement>{{languageService.getItem(16)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweBookMyselfAbscent']" *ngIf="timereport.MyselfAbscentAccess >= 2"></swe-adminrule>
  <div class="d-grid gap-2 mb-4" *ngIf="!loading&&timereport&&timereport.Booking&&timereport.MyselfAbscentAccess">
    <button class="btn btn-success" (click)="bookMyselfAbscentPopUp($event)"><i class="bi bi-calendar-plus"></i>&nbsp;{{languageService.getItem(941)}}</button>
  </div>
  <swe-card [header]="languageService.getItem(52)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="!loading&&othertimereports&&othertimereports.length>1">
    <div class="row py-2">
      <div class="col-12" *ngFor="let groupby of groupedby | keyvalue">
        <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
          <h2>{{groupby.value.header}}</h2>
        </a>
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning': othertimereport.Id==settingService.lasttimereport}" *ngFor="let othertimereport of groupby.value.items" (click)="goto(othertimereport.Id, $event)">
          <div class="col-12 col-md-3">{{othertimereport.dateheader}}</div>
          <div class="col-12 col-md-6">{{othertimereport.Name}}</div>
          <div class="col-12 col-md-3">{{othertimereport.BookingTypeName}}</div>
        </a>
      </div>
    </div>
  </swe-card>
</swe-card>

<swe-abscentdata [buttontext]="languageService.getItem(941)" [id]="id" [userid]="permissionService.user.Id" [bookingid]="timereport.Booking" [(visible)]="popup[0].Visible" (doneChange)="reloadOnAbscent()" [option]="popup[0].Option"  *ngIf="timereport&&timereport.Booking"></swe-abscentdata>
