import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { Router } from '@angular/router';


@Component({
  selector: 'swe-homenews',
  templateUrl: './homenews.component.html',
})
export class HomeNewsComponent implements OnInit {

  private _news: any[];
  private _open: boolean;
  private _loading: boolean;
  private _more: boolean = false;
  private _top: number = 3;
  private _multiple: number = 1;

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private viewCacheService: ViewCacheService,
    private router: Router
    ) {

    
  }

  ngOnInit() {
    this.search();
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get open() {
    return this._open;
  }
  public get more(): boolean {
    return this._more;
  }
  public get news() {
    return this._news;
  }
  
  



  /*Methods*/
  public search(getmore:boolean = false) {

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._news = [];
    }

    let filter = {
      //LevelList: this.settingService.levelList(3),
      //LevelGroups: this.settingService.level.levelgroups.join(),
      Top: this._top,
      Multiple: this._multiple,
      ShowAt: 2, //Overview
      Start: new Date().toLocaleString(),
      End: new Date().toLocaleString()
    };

    this.dataService.tokenRequest('/api/v1/news/search', 'POST', filter)
      .subscribe(res => {

        res.News.forEach((item) => {

          if (item.Link != item.LinkName) {
            item.LinkUrl = item.Link + '|' + item.LinkName;
          }
          else {
            item.LinkUrl = item.Link;
          }

          item.TempIsPublic = item.IsPublic;

          this._news.push(item);
        });

        if (!getmore) {
          this._open = this._news.length > 0;
        }

        this._more = res.More;
      });
    
  }
  public openLink(obj, e) {

    e.stopPropagation();

    window.open(obj.Link, '_blank');
  }
  public goto(id) {

    if (id == 0) {
      this.viewCacheService.add('news_show', 2);
    }

    this.router.navigate(['/admin/news', id]);

  }
  public wash(s: string) {
    if (s && s.length > 0) {
      return s.replace(/\n/g, '<br>');
    }
    return s;
  }





  //Functions

}
