<div class="mb-{{(access<2)?0:bottomMargin}}" [ngClass]="{'row': (cols > 0), 'swe-invalid-profile border-danger text-danger': invalid}" [ngSwitch]="type">
  <label *ngIf="statusLabel>0" [ngClass]="getLabelClass()" [ngStyle]="{'cursor': isClickableLabel() ? 'pointer':'auto'}" (click)="clickableLabel()">
    <span *ngIf="!hideLabel">{{label}}</span>
    <span *ngIf="!hideLabel&&markRequired&&!optional"> *</span>
    <swe-infotext [text]="description" [showtitle]="true" *ngIf="!hideLabel&&description&&description.length>0&&!hint"></swe-infotext>
    <swe-infotext [text]="hint" [showtitle]="true" [isHtml]="false" *ngIf="!hideLabel&&hint&&hint.length>0"></swe-infotext>
    <swe-history [propertyId]="propertyId" [objectId]="objectId" [type]="type" *ngIf="!hideLabel&&track&&objectId>0"></swe-history>
    <span class="d-block small fst-italic" *ngIf="!hideLabel&&showcounter&&!hideCounter">({{counter}}/{{max}})</span>
  </label>
  <div [ngClass]="getInputClass()" *ngIf="access>1">
    <swe-infotext [text]="description" [showtitle]="true" *ngIf="statusLabel==0&&description&&description.length>0"></swe-infotext>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.DateTime'" [hideSeconds]="container.hideSeconds" [alwaysshowcontents]="container.alwaysshowcontents"></swe-datetime>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideTime]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.Date'" [alwaysshowcontents]="container.alwaysshowcontents"></swe-datetime>
    <swe-datetime [startChanged]="isChanged" [markChanges]="container.markChanges" [(start)]="model" (startChange)="manageChange()" [labelStart]="label" [hideEnd]="true" [hideDate]="true" [hideReset]="container.hideReset" [disabled]="disabled" *ngSwitchCase="'System.Time'" [hideSeconds]="container.hideSeconds"></swe-datetime>
    <input type="password" autocomplete="new-password" class="form-control" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.Password'" (keydown)="keydown($event)" [attr.enterkeyhint]="enterKeyHint" #sweFocusElement />
    <input type="number" class="form-control" step="1" [ngClass]="{'bg-warning': isChanged}" [ngStyle]="styleobject" [min]="container.min" [max]="container.max" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Int32'" #sweFocusElement />
    <input type="number" class="form-control" step="0.01" [ngClass]="{'bg-warning': isChanged}" [ngStyle]="styleobject" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Double'" #sweFocusElement />
    <div class="pt-1" *ngSwitchCase="'System.Boolean'"><a href="javascript:void(0);" class="swe-no-link" (click)="manageBoolean()" (keydown)="keydown($event, true)" #sweFocusElement><i class="bi bi-swe-fw bi-swe align-bottom" [ngClass]="{'bi-check-square': model, 'bi-square': !model, 'swe-click': !disabled, 'swe-grayed-out': disabled, 'bg-warning': isChanged}"></i></a></div>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.List'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <option *ngFor="let item of getManagedItems()" [value]="(item.Key && item.Key.length>0) ? item.Key : item.Id">{{item.Name}}</option>
    </select>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.GroupByList'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <optgroup *ngFor="let groupby of items" label="{{groupby.Name}}">
        <option *ngFor="let item of groupby.Items" [value]="item.Id">{{item.Name}}</option>
      </optgroup>
    </select>
    <swe-multiple [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [propertyId]="propertyId" [label]="label" [items]="items" [disabled]="disabled" [isbit]="container.isbit" [min]="container.min" [max]="container.max" *ngSwitchCase="'System.MultiList'">
    </swe-multiple>
    <swe-multiple [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [propertyId]="propertyId" [label]="label" [items]="items" [disabled]="disabled" [valueAsString]="true" [min]="container.min" [max]="container.max" *ngSwitchCase="'System.MultiListAsString'">
    </swe-multiple>
    <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" multiple [style.height]="cssheight" *ngSwitchCase="'System.GroupByMultiList'">
      <option *ngIf="optional" [value]="optionalValue"></option>
      <optgroup *ngFor="let groupby of items" label="{{groupby.Name}}">
        <option *ngFor="let item of groupby.Items" [value]="item.Id">{{item.Name}}</option>
      </optgroup>
    </select>
    <swe-radio [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [items]="items" [extra]="container.extra" [disabled]="disabled" *ngSwitchCase="'System.RadioList'">
    </swe-radio>
    <swe-checkbox [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [propertyId]="propertyId" [label]="label" [items]="items" [extra]="container.extra" [disabled]="disabled" [isbit]="container.isbit" [min]="container.min" [max]="container.max" *ngSwitchCase="'System.CheckboxList'">
    </swe-checkbox>
    <input type="color" class="form-control" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.Color'" />
    <swe-color [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [items]="items" [disabled]="disabled" *ngSwitchCase="'System.ColorList'">
    </swe-color>
    <swe-document [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [disabled]="disabled"
                  [clientOnly]="container.clientOnly" [encoding]="container.encoding" [allowedPrefix]="container.allowedPrefix" [folder]="container.folder"
                  [document]="container.document" [showimage]="container.showimage" [showdocument]="container.showdocument" [reload]="container.reload"
                  [hidedownload]="container.hidedownload" [writeAccess]="container.writeaccess??true"
                  *ngSwitchCase="'Internetplatsen.Document'">
    </swe-document>
    <swe-checkboxtristate [(model)]="model" [markChanges]="container.markChanges" (modelChange)="manageChange()" [optionLanguages]="container.optionLanguages" *ngSwitchCase="'System.Tristate'"></swe-checkboxtristate>
    <textarea class="form-control" [attr.maxlength]="maxLength > 0 ? maxLength : null" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" [attr.rows]="container.rows" [ngStyle]="{resize: container.resize}" (keydown)="keydown($event, true)" (keyup)="keyup($event)" *ngSwitchCase="'System.TextArea'" #sweFocusElement></textarea>
    <swe-levelsearch [(model)]="model" (modelChange)="manageChange()" [markChanges]="container.markChanges" [displayname]="display" [onlyone]="true" [label]="label" [optional]="optional" [type]="container.type" [disabled]="disabled" [storeAtClient]="container.storeAtClient" *ngSwitchCase="'System.LevelSearch'"></swe-levelsearch>
    <swe-usersearch [(model)]="model" (modelChange)="manageChange()" [markChanges]="container.markChanges" [displayname]="display" [onlyone]="true" [label]="label" *ngSwitchCase="'System.UserSearch'"></swe-usersearch>
    <swe-contractsearch [(model)]="model" (modelChange)="manageChange()" [markChanges]="container.markChanges" [displayname]="display" [onlyone]="true" [loadOnClick]="container.loadOnClick" [label]="label" [disabled]="disabled" *ngSwitchCase="'System.ContractSearch'"></swe-contractsearch>
    <swe-filteredmultiple [(model)]="model" (modelChange)="manageChange()" [items]="items" [displayname]="display" [label]="label" [disabled]="disabled" *ngSwitchCase="'System.FilteredMultiple'" [container]="container"></swe-filteredmultiple>
    <swe-filteredcheckbox [(model)]="model" (modelChange)="manageChange()" [items]="items" [displayname]="display" [label]="label" [disabled]="disabled" *ngSwitchCase="'System.FilteredCheckboxList'" [container]="container"></swe-filteredcheckbox>
    <ng-container *ngIf="linkEdit==1">
      <label class="col-form-label" *ngSwitchCase="'System.Link'">
        <a [target]="'_blank'" [href]="container.Url">{{container.Link}}</a>
      </label>
      <label class="col-form-label" *ngSwitchCase="'System.InternalLinkList'">
        <a *ngIf="!disabled" [routerLink]="container.Url">{{container.Link}}</a>
        <span *ngIf="disabled">{{container.Link}}</span>
      </label>
    </ng-container>
    <div *ngSwitchCase="'System.InternalLinkList'" class="float-end">
      <a *ngIf="!disabled" href="javascript:void(0);" class="swe-no-link" (click)="toggleLink()"><i class="bi bi-swe-pull-right swe-click" [ngClass]="{'bi-pencil-fill': linkEdit==1,'bi-x-lg': linkEdit==2}"></i></a>
    </div>
    <a href="javascript:void(0);" class="swe-no-link float-end" (click)="toggleLink()" *ngSwitchCase="'System.Link'"><i class="bi bi-swe-pull-right swe-click" [ngClass]="{'bi-pencil-fill': linkEdit==1,'bi-x-lg': linkEdit==2}"></i></a>
    <ng-container *ngIf="linkEdit==2">
      <select class="form-control form-select" [ngClass]="{'bg-warning': isChanged}" [(ngModel)]="model" (ngModelChange)="manageChange()" [attr.aria-label]="label" [disabled]="disabled" *ngSwitchCase="'System.InternalLinkList'">
        <option *ngIf="optional" [value]="optionalValue"></option>
        <option *ngFor="let item of getManagedItems()" [value]="item.Id">{{item.Name}}</option>
      </select>
      <input type="text" [attr.maxlength]="maxLength > 0 ? maxLength : null" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Link'" />
    </ng-container>
    <span class="text-danger" *ngSwitchCase="'Internetplatsen.WarningInfo'">{{model}}</span>
    <swe-maps [(model)]="model" (modelChange)="manageChange()" [disabled]="disabled" *ngSwitchCase="'Internetplatsen.Coordinate'"></swe-maps>
    <input type="text" [attr.maxlength]="maxLength > 0 ? maxLength : null" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull, 'is-invalid': !isValid}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.String'" #sweFocusElement />
    <input type="text" [attr.maxlength]="maxLength > 0 ? maxLength : null" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull, 'is-invalid': !isValid}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Cellphone'" #sweFocusElement />
    <input type="text" [attr.maxlength]="maxLength > 0 ? maxLength : null" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull, 'is-invalid': !isValid}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Email'" #sweFocusElement />
    <input type="text" [attr.maxlength]="maxLength > 0 ? maxLength : null" class="form-control" [ngClass]="{'bg-warning': isChanged||isNull, 'is-invalid': !isValid}" [(ngModel)]="model" (ngModelChange)="manageChange()" placeholder="{{placeholder}}" [attr.aria-label]="label" [disabled]="disabled" (keydown)="keydown($event)" *ngSwitchCase="'System.Text'" #sweFocusElement />
    <div class="invalid-feedback">
      <span>{{errorMessage}}</span>
    </div>
    <div class="small text-danger" *ngIf="warning&&warning.length>0">
      <span>{{warning}}</span>
    </div>
  </div>
  <!--<div class="h-100" [ngClass]="getInputClass()" *ngIf="access<2">-->
  <label class="py-1" [ngClass]="getInputClass()" *ngIf="access<2">
    <swe-document [changed]="isChanged" [(model)]="model" (modelChange)="manageChange()" [label]="label" [disabled]="disabled"
                  [clientOnly]="container.clientOnly" [encoding]="container.encoding" [allowedPrefix]="container.allowedPrefix" [folder]="container.folder"
                  [document]="container.document" [showimage]="container.showimage" [showdocument]="container.showdocument" [reload]="container.reload"
                  [hidedownload]="container.hidedownload" [writeAccess]="false"
                  *ngSwitchCase="'Internetplatsen.Document'">
    </swe-document>
    <span *ngSwitchCase="'System.DateTime'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Date'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Time'">{{dateTimeService.format(model, format)}}</span>
    <span *ngSwitchCase="'System.Int32'">{{model}}</span>
    <span *ngSwitchCase="'System.Double'">{{formatvalue||model}}</span>
    <i class="bi bi-swe swe-disabled swe-grayed-out" [ngClass]="{'bi-square': !model,'bi-check-square': model}" *ngSwitchCase="'System.Boolean'"></i>
    <i class="bi bi-swe swe-disabled swe-grayed-out" [ngClass]="{'bi-dash-square': model  == 0, 'bi-check-square': model == 2, 'bi-square': model == 1}" *ngSwitchCase="'System.Tristate'"></i>
    <span *ngSwitchCase="'System.LevelSearch'">{{display}}</span>
    <span *ngSwitchCase="'System.List'"><span class="p-1 ps-0" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.RadioList'"><span class="p-1 ps-0" *ngFor="let item of getReadOnlyItems()">{{languageService.getItem(item.Name)}}</span></span>
    <span *ngSwitchCase="'System.FilteredMultiple'"><span class="p-1 ps-0" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.FilteredCheckboxList'"><span class="p-1 ps-0" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.MultiList'"><span class="p-1 ps-0" *ngFor="let item of getReadOnlyItems()">{{item.Name}}</span></span>
    <span *ngSwitchCase="'System.Link'"><a [target]="'_blank'" [href]="container.Url">{{container.Link}}</a></span>
    <span *ngSwitchCase="'System.InternalLinkList'">
      <span *ngIf="!disabled"><a [routerLink]="container.Url">{{container.Link}}</a></span>
      <span *ngIf="disabled">{{container.Link}}</span>
    </span>
    <swe-maps [(model)]="model" [disabled]="true" *ngSwitchCase="'Internetplatsen.Coordinate'"></swe-maps>
    <span *ngSwitchCase="'System.TextArea'" [innerText]="model"></span>
    <span *ngSwitchCase="'System.String'">{{model}}</span>
    <span class="text-danger" *ngSwitchCase="'Internetplatsen.WarningInfo'">{{model}}</span>
    <span *ngSwitchCase="'System.Text'">{{model}}</span>
    <span *ngSwitchDefault>{{model}}</span>
  </label>
  <div [ngClass]="getExtraClass()" *ngIf="showinheritance">
    <button class="btn float-end" [ngClass]="{'btn-outline-primary': !inherit, 'btn-primary': inherit}" [attr.title]="inheritancetitle" (click)="toggleInheritance()" *ngIf="showinheritance"><i class="bi bi-swe-fw bi-diagram-3"></i></button>
  </div>
  <!--</div>-->
  <ng-content select="[swe-footer]"></ng-content>
</div>
