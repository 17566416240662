import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _users: any[] = [];
  private _isloading: boolean = false;
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _maxrows: number = 0;
  private _actionmenu: boolean = false;
  private _belongingmenu: boolean = false;
  private _belongingtext: string = '';
  private _listutility: ListUtility = new ListUtility();

  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private viewCacheService: ViewCacheService,
    private router: Router) {

    settingService.initView(2 | 8 | 16 | 512, ['User', 'Level']);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {

    this._listutility.toggleaccess = (this.permissionService.permissions.Email > 0 || this.permissionService.permissions.Sms > 0 || this.permissionService.permissions.MultiUser);

    this.search();
  }

  /*Properties*/
  public get users() {
    return this._users;
  }
  public get isloading() {
    return this._isloading;
  }
  public get more() : boolean {
     return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  public get maxRows(): number {
    return this._maxrows;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get belongingmenu() {
    return this._belongingmenu;
  }
  public set belongingmenu(val) {
    this._belongingmenu = val;
  }
  public get belongingtext() {
    return this._belongingtext;
  }
  public get listutility() {
    return this._listutility;
  }

  public handleSearchEvent($event) {
    this.search($event.getMore);
  }

  /*Methods*/
  public search(getmore: boolean = false) {

    this.manageBelonging();

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._users = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      IncludeNoBelonging: this.settingService.user.nobelonging,
      Active: this.settingService.user.activity,
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      Available: this.settingService.user.availability,
      AvailableTypes: this.settingService.user.availabilitylist.join(),
      Employment: this.settingService.user.employment,
      EmploymentCategories: this.settingService.user.employmentcategories,
      FrameworkContracts: this.settingService.user.frameworkcontracts,
      Contract: this.settingService.user.contract,
      Contracts: this.settingService.user.contracts,
      Booking: this.settingService.user.bookingtype,
      LevelGroups: this.settingService.user.levelgroups.join(),
      LevelList: this.settingService.levelList(1),
      OnlyParentLevelList: this.settingService.levelList(1, true),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this._isloading = true;

    this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          this._more = res.More;
          this._maxrows = res.MaxRows;

          res.Users.forEach(user => {
            this._users.push(user);
          });

          this._hits = this._users.length;
          this._listutility.rows = this._users;
          this._isloading = false;
        }
      });
  }
  public open(id: number, e) {
    let url = '/users/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public changebelonging() {
    this._belongingmenu = false;
    this.search(false);
  }
  public send(type) {

    let users = [];
    this._users.forEach((user) => {
      if (user.checked) {
        users.push(user);
      }
    });
    this.viewCacheService.add('message_users', users);

    let url = '';
    if (type == 1) {
      url = '/messages/send/email';
    }
    else if (type == 3) {
      url = '/messages/send/sms';
    }

    this.router.navigate([url]);
  }
  public action(option) {

    let users = [];
    this._users.forEach((user) => {
      if (user.checked && user.Access > 1) {
        users.push(user.Id);
      }
    });

    if (users.length > 0) {

      this.viewCacheService.add('multi_users', users);

      this.router.navigate(['/users/action/' + option + '/']);
    }
  }




  private manageBelonging() {
    if (this.settingService.user.nobelonging == 1) {
      this._belongingtext = this.languageService.getItem(104);
    }
    else if (this.settingService.user.nobelonging == 2) {
      this._belongingtext = this.languageService.getItem(1038);
    }
    else {
      this._belongingtext = this.languageService.getItem(1037);
    }
  }

}
