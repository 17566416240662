import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { GeneralService } from '../_services/general.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_services/alert.service';
import { DateTimeService } from '../_services/datetime.service';
import { PropertyService } from '../_services/property.service';
import { ListService } from '../_services/list.service';


@Component({
  selector: 'app-bookingwizard',
  templateUrl: './bookingwizard.component.html'
})
export class BookingWizardComponent implements OnInit {

  //Inparameters
  private _id: number = 0;
  private _userid: number = 0;
  private _levelid: number = 0;
  //Settings
  private _loading: boolean = false;
  //Steps
  private _higheststep: number = 0;
  private _steps: any[] = [];
  private _currentstep: any = {};
  //PropertyTabGroups
  private _propertytabgroups: any[] = [];
  //Booking
  private _booking: any = {};
  private _otheraccess: boolean = false;
  private _statusCreated: boolean = false;
  private _statusOrdered: boolean = false;
  private _saving: boolean = false;
  //Validation
  private _validation: any;
  //TimeTypes
  private _plustimetypes: any[] = [];
  private _neutraltimetypes: any[] = [];
  private _minustimetypes: any[] = [];
  //Series
  private _series: any[] = [];
  private _isSerie: boolean;
  private _serieOptionList: any[] = [];
  private _serieOption: number = this.permissionService.permissions.SerieCopyAsDefault ? 2 : 1;
  private _endOption: number = 1;
  private _includeUsers: boolean;
  //Activities
  private _activities: any[] = null;
  private _tmpcounter: number = -1;


  //Employment categories
  private _employmentcategories: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {

      this._id = Number(params.get('id'));
      this._userid = Number(params.get('userid'));
      this._levelid = Number(params.get('levelid'));

      if (this._id > 0) {
        //Not allowed to update shift by wizard
        this.router.navigate(['/']);
      }

      this.init();
    });

      this.generalService.employmentcategories.forEach(category => {
          this._employmentcategories.push({ Id: category.Key, Name: category.Value });
      });
  }


  //************************************************************************************/
  //Properties
  //************************************************************************************/
  public get id() {
    return this._id;
  }
  public get userid() {
    return this._userid;
  }
  public get levelid() {
    return this._levelid;
  }
  //Settings
  public get loading() {
    return this._loading;
  }
  //Steps
  public get higheststep() {
    return this._higheststep;
  }
  public get steps() {
    return this._steps;
  }
  public get currentstep() {
    return this._currentstep;
  }
  public get laststep() {
    if (this._steps.length > 0) {
      return this._steps[this._steps.length - 1];
    }

    return this.emptystep();
  }
  public get secondlaststep() {
    if (this._steps.length > 1) {
      return this._steps[this._steps.length - 2];
    }

    return this.emptystep();
  }
  //Booking
  public get booking() {
    return this._booking;
  }
  public set booking(val) {
    this._booking = val;
  }
  public get otheraccess() {
    return this._otheraccess;
  }
  public get statusCreated() {
    return this._statusCreated;
  }
  public get statusOrdered() {
    return this._statusOrdered;
  }
  public get saving() {
    return this._saving;
  }
  public get header() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.HeaderProperty);
    }
    return {};
  }
  public get max() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.MaxProperty);
    }
    return {};
  }
  //Validation
  public get validation() {
    return this._validation;
  }
  //TimeTypes
  public get timetypes() {
    return this.getTimeTypes([0, 1]);
  }
  //Series
  public get series() {
    return this._series;
  }
  public get isSerie() {
    return this._isSerie;
  }
  public set isSerie(val) {
    this._isSerie = val;
  }
  public get serieOption() {
    return this._serieOption;
  }
  public set serieOption(val) {
    this._serieOption = val;
  }
  public get endOption() {
    return this._endOption;
  }
  public set endOption(val) {
    this._endOption = val;
  }
  public get includeUsers() {
    return this._includeUsers;
  }
  public set includeUsers(val) {
    this._includeUsers = val;
  }
  public get serieOptionList() {
    return this._serieOptionList;
  }
  public get serieTypeList() {
    return [
      { Id: 0, Name: this.languageService.getItem(813) },
      { Id: 1, Name: this.languageService.getItem(256) },
      { Id: 2, Name: this.languageService.getItem(257) },
      { Id: 3, Name: this.languageService.getItem(258) },
      { Id: 4, Name: this.languageService.getItem(259) },
      { Id: 5, Name: this.languageService.getItem(260) },
      { Id: 6, Name: this.languageService.getItem(261) },
      { Id: 7, Name: this.languageService.getItem(262) },
      { Id: 8, Name: this.languageService.getItem(263) },
      { Id: 9, Name: this.languageService.getItem(264) },
      { Id: 10, Name: this.languageService.getItem(265) }
    ];
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  //PropertyTabGroups
  public get propertytabgroups() {
    return this._propertytabgroups;
  }
  //Container
  public get container() {
    return {
      isbit: true
    };
  }
  //Activities
  public get activities() {
    return this._activities;
  }

  //Employment categories
  public get employmentcategories() {
    return this._employmentcategories;
  }

  //************************************************************************************/
  //************************************************************************************/
  //************************************************************************************/


  //************************************************************************************/
  //Methods
  //************************************************************************************/
  public movestep(step) {
    this._currentstep = step;
  }
  public next() {
    if (this._currentstep.Index < this._steps.length-1) {

      //Validation
      if (this._currentstep.Id == 'DateTime') {
        if (!this.validatedatetime() || !this.validatebreak()) { return; }
        if (!this.validatetimetypes()) { return; }
      }
      else if (this._currentstep.Id == 'Other') {
        if (!this.validatecorevalues()) { return; }
      }

      //Move to next
      this._currentstep = this._steps[this._currentstep.Index + 1];
      if (this._higheststep < this._currentstep.Index) {
        this._higheststep = this._currentstep.Index;
      }
    }
  }
  public levelchosen(e) {
    //Get
    if (this.permissionService.permissions.ReloadProfile && e > 0) {
      this._loading = true;

      let url = '/api/v1/bookings/' + this.id;
      if (e > 0) {
        url += '/level/' + e;
      }

      this.dataService.tokenRequest(url, 'GET')
        .subscribe(res => {
          if (res) {
            this._booking.Access = res.Access;
            this._booking.Filled = res.Filled;
            this._booking.Max = res.Max;
            this._booking.Profile = res.Profile;

            if (e == 0) {
              if (!this.permissionService.permissions.EmptyBookingLevel) {

                //Storage
                let storage = localStorage.getItem('level');
                if (!storage) {
                  storage = this.permissionService.permissions.BookingLevel;
                }
                if (storage) {
                  let storageArr = storage.split('|');
                  this._booking.LevelId = storageArr[0];
                  if (storageArr.length > 1) {
                    this._booking.LevelName = storageArr[1];
                  }
                  if (storageArr.length > 2) {
                    this._booking.LevelPath = storageArr[2];
                  }
                }
              }
            }
            else if (e > 0 && e != res.LevelId) {
              //Changed Level on existing Shift
              this.dataService.tokenRequest('/api/v1/levels/' + e, 'GET')
                .subscribe(resLevel => {

                  this._booking.LevelId = resLevel.Id;
                  this._booking.LevelName = resLevel.Name;
                  this._booking.LevelPath = resLevel.Path;
                });
            }
            else {
              //Changed Level on new Shift
              this._booking.LevelId = res.LevelId;
              this._booking.LevelName = res.LevelName;
              this._booking.LevelPath = res.LevelPath;
            }
          }

          this._loading = false;
        });
    }
    else {
      this.dataService.tokenRequest('/api/v1/levels/' + e, 'GET')
        .subscribe(resLevel => {

          this._booking.LevelId = resLevel.Id;
          this._booking.LevelName = resLevel.Name;
          this._booking.LevelPath = resLevel.Path;
        });
    }

  }
  public userchosen(e) {
    //Get
    this.dataService.tokenRequest('/api/v1/users/' + e + '/small', 'GET')
      .subscribe(resUser => {

        this._booking.UserId = resUser.Id;
        this._booking.User = resUser.Firstname + ' ' + resUser.Lastname + ' (' + resUser.Username + ')';
      });
  }
  public manageDateTime(e) {

    if (this.permissionService.permissions.BreakStart) {
        for (let i = 0; i < this._booking.Breaks.length; i++) {
          if (new Date(this._booking.Breaks[i].BreakStart) > new Date(1970, 1, 1)) {
            this._booking.Breaks[i].BreakStart = this.adjustbreakstart(new Date(this._booking.Start), new Date(this._booking.End), new Date(this._booking.Breaks[i].BreakStart));
          }
        }
    }

  }
  public changeSerieType(e) {
    this._isSerie = (this._booking.Serie && this._booking.Serie.Type > 0);
  }
  public save() {

    if (!this.validatedatetime() || !this.validatebreak()) { return; }
    if (!this.validatetimetypes()) { return; }
    if (!this.validatecorevalues()) { return; }
    if (!this.propertyService.validate(this._booking.Profile)) { return; }

    this._saving = true;

    let verb = 'POST';
    let path = '/api/v1/bookings/'

    //Manage Break
    for (let i = 0; i < this._booking.Breaks.length; i++) {
      if (new Date(this._booking.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._booking.Breaks[i].Break <= 0) {
        if (this.permissionService.permissions.BreakStart || this._booking.Breaks[i].Break <= 0) {
          this._booking.Breaks.splice(i, 1);
          i--;
        }
        else {
          this._booking.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
        }
      }
    }

    let dto: any = {
      LastModified: this._booking.LastModified,
      Start: this._booking.Start,
      End: this._booking.End,
      Breaks: this._booking.Breaks,
      Header: this._booking.Header,
      Max: this._booking.Max,
      Profile: this._booking.Profile,
      MealBreak: this._booking.MealBreak,
      Serie: this._booking.Serie,
      RemoveATK: this._booking.RemoveATK,
      EmploymentCategoryId: this._booking.EmploymentCategoryId ? this._booking.EmploymentCategoryId : 0,
      TimeType: this._booking.TimeType && this._booking.TimeType != 'undefined' ? this._booking.TimeType : 0
    };

    if (this.permissionService.permissions.HideLevels) {
      dto["LevelId"] = -1;
    }
    else {
      dto["LevelId"] = this._booking.LevelId;
    }
    dto["UserId"] = this._booking.UserId;
    if (this.permissionService.permissions.HideStatus) {
      dto["Status"] = 20;
    }
    else {
      dto["Status"] = this._booking.Status > 0 ? this._booking.Status : 10;
    }
    dto["Auto"] = this._booking.Auto;
    dto["EmploymentPlan"] = this._booking.EmploymentPlan;
    

    //Series
    if (this._isSerie && this._booking.Serie.Id == 0) {
      dto["Serie"]["IncludeUsers"] = this._includeUsers;
      dto["Serie"]["Action"] = this._serieOption;
      if (this._endOption == 0) {
        dto["Serie"]["End"] = new Date('0001-01-01T00:00:00Z');
      }
      else {
        dto["Serie"]["Times"] = 0;
      }
    }

    if (this._booking.Id == 0 && this._activities != null) {
      dto["Activities"] = this._activities.filter(function (activity) { return activity.Id < 0; });
      if (dto["Activities"] != null) {
        let bStart = this._booking.Start;
        let bEnd = this._booking.End;
        for (let i = 0; i < dto["Activities"].length; i++) {
          let aStart = dto["Activities"][i].Start;
          let aEnd = dto["Activities"][i].End;
          let startOutsideSpan = (aStart < bStart || aStart > bEnd);
          let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
          if (startOutsideSpan || endOutsideSpan) {
            this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
            this._saving = false;
            return;
          }
        }
      }
    }

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this._saving = false;

          let type = 'success';
          if (response.body != this.languageService.getItem(18)) {
            type = 'warning';
          }

          this.alertService.Add({ message: response.body, type: type });

          this._id = response.headers.get('Location').replace('bookings/', '');

          this._currentstep = this._steps[this._steps.length - 1];
          this._higheststep = this._currentstep.Index;
        }
      }, (error) => {
        this._saving = false;
      });

  }
  public gotoHome() {
    let url = '/';
    this.router.navigate([url]);
  }
  public gotoShift() {
    let url = '/bookings';
    this.router.navigate([url, this._id]);
  }
  //************************************************************************************/
  //************************************************************************************/
  //************************************************************************************/




  //************************************************************************************/
  //Functions
  //************************************************************************************/
  private init() {

    //Serie option list
    this._serieOptionList.push({ Id: 1, Name: 252 });
    this._serieOptionList.push({ Id: 2, Name: 253 });

    //Status
    this.generalService.bookingstatus.forEach((status) => {
      if (status.Id == 10) {
        this._statusCreated = true;
      }
      else if (status.Id == 20) {
        this._statusOrdered = true;
      }
    });

    

    //Validation
    this._validation = {
      datetime: true,
      break: true,
      timetype: true,
      header: true
    }

    //PropertyTabGroups
    this.generalService.propertytabgroups.forEach((tabgroup) => {

      this._propertytabgroups.push({ Id: tabgroup.Key, Name: tabgroup.Value, Desc: tabgroup.Extra });
      
    });

    //TimeTypes
    this.generalService.timetypes.forEach((timetype) => {

      if (timetype.Extra < 0) {
        this._minustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else if (timetype.Extra == 0) {
        this._neutraltimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else {
        this._plustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }

    });

    
    

    this.load();
  }
  private load(levelid: number = 0) {

    let url = '/api/v1/bookings/' + this._id;
    if (this._levelid > 0) {
      url += '/level/' + this._levelid;
    }

    this._loading = true;

    this.dataService.tokenRequest(url, 'GET')
      .subscribe(res => {

        if (res) {
          this._booking = res;

          this._otheraccess = (
            this._booking.HeaderAccess > 0
            ||
            this._booking.MaxAccess > 0
            ||
            this.permissionService.permissions.Owner > 0
            ||
            this._booking.AutoAccess > 0
          );

          if (this._levelid > 0) {
            //Get
            this.levelchosen(this._levelid);
          }
          else if (!this.permissionService.permissions.EmptyBookingLevel) {
            //Storage
            let storage = localStorage.getItem('level');
            if (!storage) {
              storage = this.permissionService.permissions.BookingLevel;
            }
            if (storage) {
              let storageArr = storage.split('|');
              this._booking.LevelId = storageArr[0];
              if (storageArr.length > 1) {
                this._booking.LevelName = storageArr[1];
              }
              if (storageArr.length > 2) {
                this._booking.LevelPath = storageArr[2];
              }
            }
          }

          //Force to chose status
          this._booking.Status = 0;

          /*Timetype*/
          if (this._booking.TimeType == 0 && this._neutraltimetypes.length > 0) {
            this._booking.TimeType = this._neutraltimetypes[0].Id;
          }

          //Serie
          this._isSerie = false;
          this._endOption = 1;
          this._booking.Serie = {
            Id: 0,
            'Type': 0,
            Days: Math.pow(2, new Date(res.Start).getDay()),
            Times: 1,
            End: this.dateTimeService.lastDayInWeek(new Date(res.Start))
          };

          if (this._booking.Id == 0) {
            this.levelchosen(this._booking.LevelId);
          }

          this.loadSteps();
          this.loadActivities();
        }

        this._loading = false;
      });
  }
  private loadSteps() {

    let index = 0;

    //Level step
    if (!this.permissionService.permissions.HideLevels) {
      this._steps.push({ Id: 'Level', Index: index++, Name: this.languageService.getItem(1156), Header: this.languageService.getItem(1157), Key: -1 });
    }

    //DateTimes step
    this._steps.push({ Id: 'DateTime', Index: index++, Name: this.languageService.getItem(1159), Header: this.languageService.getItem(1160), Key: -2 });

    //Other step
    this._steps.push({ Id: 'Other', Index: index++, Name: this.languageService.getItem(1164), Header: this.languageService.getItem(1165), Key: -3 });

    //Activities step
    if (this.permissionService.permissions.Activity > 0) {
      this._steps.push({ Id: 'Activity', Index: index++, Name: this.languageService.getItem(395), Header: this.languageService.getItem(1170), Key: -4 });
    }

    //Profile steps
    let tabgroups = this.tabgroups();
    Object.entries(tabgroups).forEach(([key, value]) => {
      if (parseInt(key) > 0 && value['header'].length > 0) {
        let desc = '';
        let tabgroup = this.listService.find(this._propertytabgroups, 'Id', key);
        if (tabgroup && tabgroup.Desc) {
          desc = tabgroup.Desc;
        }

        this._steps.push({ Id: 'Properties', Index: index++, Name: value['header'], Header: desc, Key: key });
      }
    });

    //BookingStatus step
    if (!this.permissionService.permissions.HideStatus) {
      this._steps.push({ Id: 'Status', Index: index++, Name: this.languageService.getItem(1171), Header: this.permissionService.permissions.HasStatusOrdered ? this.languageService.getItem(1172) : this.languageService.getItem(1171) });
    }

    //Result step
    this._steps.push({ Id: 'Result', Index: index++, Name: this.languageService.getItem(1175), Header: this.languageService.getItem(1176) });

    //Current step
    this._currentstep = this._steps[0];
  }
  private emptystep() {
    return {
      Id: -1,
      Index: -1,
      Name: '',
      Header: '',
      Key: -1
    };
  }
  private tabgroups() {

    //Distinct
    let flags = {};
    let distinct = this._booking.Profile.filter(function (entry) {
      if (flags[entry.TabGroupSort]) {
        return false;
      }
      flags[entry.TabGroupSort] = true;
      return true;
    });

    let tabgrouplist = distinct.map(function (tabgroup) { return { Id: tabgroup.TabGroupBy, Name: tabgroup.TabGroupName, Sort: tabgroup.TabGroupSort }; });
    this._steps.slice().reverse().forEach((step) => {
      tabgrouplist.unshift({ Id: step.Key, Name: step.Name, Sort: step.Key });
    });

    return this.listService.groupByInt(this._booking.Profile, 'TabGroupBy', tabgrouplist);
  }

  private adjustbreakstart(start: Date, end: Date, breakstart: Date) {

    let newBreakStart: Date = breakstart;

    if ((newBreakStart < start || end < newBreakStart)) {
      //Adjust to start date
      newBreakStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
      if ((newBreakStart < start || end < newBreakStart)) {
        //Adjust to end date
        newBreakStart = new Date(end.getFullYear(), end.getMonth(), end.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
        if ((newBreakStart < start || end < newBreakStart)) {
          //Can't adjust
          newBreakStart = breakstart;
        }
      }
    }

    return this.dateTimeService.formatWithTimeZone(newBreakStart);
  }
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Name: '-----' });
      }

      if (sign > 0) {
        this._plustimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else if (sign == 0) {
        this._neutraltimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else {
        this._minustimetypes.forEach((item) => {
          list.push(item);
        });
      }

    });

    return list;
  }
  //************************************************************************************/
  //************************************************************************************/
  //************************************************************************************/



  //************************************************************************************/
  //Activities
  //************************************************************************************/
  private loadActivities() {
    if (this.permissionService.permissions.Activity > 0) {

      this._activities = [];

      this.dataService.tokenRequest('/api/v1/bookings/' + this._booking.Id + '/activities', 'GET')
        .subscribe(res => {
          if (res) {

            res.forEach(activity => {

              let start = new Date(activity.Start);
              let end = new Date(activity.End); 

              if (!this.permissionService.permissions.ShiftTime) {
                end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
              }

              activity.dateheader = this.dateTimeService.header(start, end, false);

              this._activities.push(activity);
            });

          }

          this._activities.unshift(this.newActivity());
          if (this._activities.length > 1) {
            this._activities[0].Start = this._activities[this._activities.length - 1].End;
          }
        });
    }
  }
  private newActivity() {
    return {
      Id: 0,
      Start: this._booking.DateTimeMin,
      End: this._booking.DateTimeMax,
      TypeId: 0,
      Text: '',
      BookingId: 0,
      isedit: false
    };
  }
  public editActivity(activity, e) {
    if (this.permissionService.permissions.Activity > 1) {
      e.stopPropagation();
      if (activity.Id == 0) {
        activity.Start = this.booking.Start;
        activity.End = this.booking.End;
      }

      activity.isedit = !activity.isedit;
    }
  }
  public saveActivity(activity, e) {
    if (activity.TypeId == 0) {
      //Take first
      activity.TypeId = this.generalService.activitytypes[0].Id;
    }

    let aStart = new Date(activity.Start);
    let aEnd = new Date(activity.End);
    let bStart = new Date(this._booking.DateTimeMin);
    if (new Date(this._booking.Start) < bStart) {
      bStart = new Date(this._booking.Start);
    }
    let bEnd = new Date(this._booking.DateTimeMax);
    if (new Date(this._booking.End) > bEnd) {
      bEnd = new Date(this._booking.End);
    }
    let startOutsideSpan = (aStart < bStart || aStart > bEnd);
    let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
    if (startOutsideSpan || endOutsideSpan) {
      this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
      return;
    }


    activity.BookingId = this._booking.Id;

    if (this._booking.Id > 0) {
      //Booking exits
      let id = activity.Id;
      let verb = 'POST';
      var path = '/api/v1/activities/'
      if (id > 0) {
        verb = 'PUT';
        path += id;
      }

      this.dataService.tokenRequest(path, verb, activity, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities();

        });
    }
    else if (this._booking.Id == 0) {
      //Booking not saved yet
      activity.isedit = false;

      if (activity.Id == 0) {
        let tmpActivity = Object.assign({}, activity); //Copy
        tmpActivity.Id = this._tmpcounter;
        this._tmpcounter--;

        let start = new Date(tmpActivity.Start);
        let end = new Date(tmpActivity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        tmpActivity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          tmpActivity.Color = activitytype[0].Color;
          tmpActivity.Type = activitytype[0].Name;
        }

        this._activities.push(tmpActivity);

        if (this._activities.length > 1) {
          this._activities[0].Start = this._activities[this._activities.length - 1].End;
        }
      }
      else {
        let start = new Date(activity.Start);
        let end = new Date(activity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        activity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          activity.Color = activitytype[0].Color;
          activity.Type = activitytype[0].Name;
        }
      }
    }
  }
  public deleteActivity(activity, e) {

    e.stopPropagation();

    if (this._booking.Id > 0) {
      //Booking exits
      this.dataService.tokenRequest('/api/v1/activities/' + activity.Id, 'DELETE', {}, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities();

        });
    }
    else {
      //Booking not saved yet
      let index = this._activities.indexOf(activity);
      if (index > -1) {
        this._activities.splice(index, 1);
      }
    }
  }
  //************************************************************************************/
  //************************************************************************************/
  //************************************************************************************/



  //************************************************************************************/
  //Validation
  //************************************************************************************/
  private validatebreak(isCopy: boolean = false) {
    if (this._booking.MealBreak)
    {
      return true;
    }
    let breaks: any[] = [{ Break: this._booking.Break, BreakStart: new Date(this._booking.BreakStart) }];
    breaks = this._booking.Breaks;
    

    let res = true;
    for (let i = 0; i < breaks.length; i++) {
      let breakObj = breaks[i];

      if (this.permissionService.permissions.BreakStart && breakObj.Break > 0) {
        if (breakObj.BreakStart == null) {
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(787) });

          res = false;
          break;
        }
        else if (!isCopy && (breakObj.BreakStart < new Date(this._booking.Start) || new Date(this._booking.End) < breakObj.BreakStart)) {
          let debugstart = this.dateTimeService.format(new Date(this._booking.Start), 'yyyy-MM-dd HH:mm');
          let debugbreak = this.dateTimeService.format(new Date(breakObj.BreakStart), 'yyyy-MM-dd HH:mm');
          let debugend = this.dateTimeService.format(new Date(this._booking.End), 'yyyy-MM-dd HH:mm');
          console.log(this.languageService.getItem(788) + ' (' + debugstart + ', ' + debugbreak + ', ' + debugend + ')');
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(788) });

          res = false;
          break;
        }
        else if ((new Date(breakObj.BreakStart).getTime() + (breakObj.Break * 60 * 1000)) > new Date(this._booking.End).getTime()) {
          let debugstart = this.dateTimeService.format(new Date(this._booking.Start), 'yyyy-MM-dd HH:mm');
          let debugbreak = this.dateTimeService.format(new Date(breakObj.BreakStart), 'yyyy-MM-dd HH:mm');
          let debugend = this.dateTimeService.format(new Date(this._booking.End), 'yyyy-MM-dd HH:mm');
          console.log(this.languageService.getItem(789) + ' (' + debugstart + ', ' + debugbreak + ', ' + debugend + ')');
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(789) });

          res = false;
          break;
        }
      }
      else if (this.permissionService.permissions.BreakStart && breakObj.Break == 0) {
        breakObj.BreakStart = null;
      }

    }

    return this._validation.break = res;
  }
  private validatedatetime() {

    let start = new Date(this._booking.Start);
    let end = new Date(this._booking.End);

    /*Manage ShiftTime*/
    if (!this.permissionService.permissions.ShiftTime) {

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    }

    /*Test*/
    return this._validation.datetime = this.dateTimeService.validation(start, end);
  }
  private validatetimetypes() {

    //TimeType
    let res = true;

    if (this.timetypes.length > 0) {
      if (this._booking.TimeType == 0) {
        this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
        res = false;
      }
      else if (this._booking.TimeType > 0) {
        let timeTypeObj = this.listService.find(this.timetypes, 'Id', this._booking.TimeType);
        if (!timeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
          res = false;
        }
      }
    }

    return this._validation.timetype = res;
  }
  private validatecorevalues() {

    //Header
    let header = this.propertyService.getPropertyByCoreValue('BookingHeader');
    if (header) {
      this._validation.header = this.propertyService.validate([{ Name: header.Name, Value: this._booking.Header, Property: header.Id, Access: 2 }]);
      if (!this._validation.header) {
        return false;
      }
    }

    return true;
  }
  //************************************************************************************/
  //************************************************************************************/
  //************************************************************************************/
}
