import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { GeneralService } from '../_services/general.service';
import { ListService } from '../_services/list.service';
import { PermissionService } from '../_services/permission.service';


@Component({
  selector: 'app-leveldetail',
  templateUrl: './leveldetail.component.html'
})
export class LevelDetailComponent implements OnInit {

  private _id: number;
  private _leveldata: any;
  private _settingaccess: number = 0;
  private _relationaccess: number = 0;
  private _useraccess: number = 0;
  private _bookingaccess: number = 0;
  private _logaccess: number = 0;
  private _objecttoobjects: any[] = [];
  private _tabs: any;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private generalService: GeneralService,
    private listService: ListService,
    private route: ActivatedRoute) {
    settingService.initView(2 | 256 | 512, [], [{ type: 'user', key: 'levelgroups' }, { type: 'user', key: 'levels' }]);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastlevel = this._id;

      this.load();
    });
  }

  /*Properties*/
  public get cards() {
    if (this._tabs) {
      return {
        data: this._tabs.data.navigate,
        settings: this._tabs.settings.navigate,
        relations: this._tabs.relations.navigate,
        users: this._tabs.users.navigate,
        bookings: this._tabs.bookings.navigate,
        logs: this._tabs.logs.navigate,
        objects: this._tabs.objects.map(x => x.navigate),
        systemlogs: false,
        messagelogs: false
      }
    }
    return {
      data: true,
      settings: false,
      relations: false,
      users: false,
      bookings: false,
      systemlogs: false,
      messagelogs: false
    };
  }
  public get id() {
    return this._id;
  }
  public get leveldata() {
    return this._leveldata;
  }
  public get settingaccess() {
    return this._settingaccess;
  }
  public get relationaccess() {
    return this._relationaccess;
  }
  public get useraccess() {
    return this._useraccess;
  }
  public get bookingaccess() {
    return this._bookingaccess;
  }
  public get logaccess() {
    return this._logaccess;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }
  public get tabs() {
    return this._tabs;
  }




  /*Methods*/
  public manageLeveldata(data) {
    this._leveldata = data;
    this._settingaccess = this._leveldata.SettingAccess;
    this._relationaccess = this._leveldata.RelationAccess;
    this._useraccess = this._leveldata.UserAccess;
    this._bookingaccess = this._leveldata.BookingAccess;
    this._logaccess = this._leveldata.LogAccess;
    this.manageTabs()
  }

  private manageTabs() {
    this._tabs = {
      data: {
        access: true,
        label: this.languageService.getItem(38),
        icon: 'bi-diagram-3',
        navigate: true
      },
      settings: {
        label: this.languageService.getItem(6),
        icon: 'bi-wrench-adjustable',
        access: this._id > 0 && this._settingaccess > 0,
        navigate: false
      },
      relations: {
        label: this.languageService.getItem(0),
        icon: 'bi-diagram-2',
        access: this._id > 0 && this._relationaccess > 0,
        navigate: false
      },
      users: {
        label: this.languageService.getItem(106),
        access: this._id > 0 && this._useraccess > 0,
        icon: 'bi-people-fill',
        navigate: false
      },
      bookings: {
        label: this.languageService.getItem(4),
        icon: 'bi-list-ul',
        access: this._id > 0 && this._bookingaccess > 1,
        navigate: false
      },
      objects: [],
      logs: {
        label: this.languageService.getItem(400),
        icon: 'bi-clock-history',
        access: this._id > 0 && this._logaccess > 0,
        navigate: false
      }
    };
    this._objecttoobjects.forEach(obj => {
      this._tabs.objects.push({
        icon: 'bi-bookmark-fill',
        label: obj.Value,
        access: this._id > 0 && this.permissionService.hasObject(obj.Key, this._id) > 0,
        navigate: false,
      });
    });
  }

  //Functions
  private load() {

    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['Level'], 'Type');

  }
}
