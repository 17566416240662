<div class="row" style="--bs-gutter-x:0;">
  <div class="col-12 col-lg-5">
    <swe-card [header]="languageService.getItem(1420)" [hasRefresh]="false">
      <div class="mb-3 row">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe bi-diagram-3-fill" [attr.title]="languageService.getItem(14)"></i>
          </div>
          <div class="swe-col-content">
            <swe-levelsearch [label]="languageService.getItem(14)" [statusLabel]="0" [(model)]="setting.LevelId" [onlyone]="true" [(displayname)]="setting.LevelName" [optional]="true"></swe-levelsearch>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe swe-icon-color" [ngClass]="{'bi-arrow-up-right-square': setting.Status==20,'bi-key-fill': setting.Status==30,'bi-clock-fill': setting.Status==40,'bi-check-lg': setting.Status==50,'bi-lock-fill': setting.Status==60}" [attr.title]="languageService.getItem(15)" *ngIf="setting.Status>10"></i>
            <i class="bi bi-swe-fw bi-swe bi-square swe-icon-color" [attr.title]="languageService.getItem(15)" *ngIf="setting.Status==10"></i>
          </div>
          <div class="swe-col-content">
            <swe-element [type]="'System.List'" [label]="languageService.getItem(15)" [statusLabel]="0" [(model)]="setting.Status" [items]="generalService.bookingstatus" [optional]="true" [optionalValue]="0"></swe-element>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe bi-quote bi-swe-rotate-180" [attr.title]="languageService.getItem(480)"></i>
          </div>
          <div class="swe-col-content">
            <swe-element [type]="'System.String'" [label]="languageService.getItem(480)" [statusLabel]="0" [(model)]="setting.Name"></swe-element>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe bi-clock-fill" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
          </div>
          <div class="swe-col-content d-md-flex">
            <swe-datetime class="me-3 text-nowrap" [(start)]="setting.Start" [end]="setting.End" [adjustEnd]="false" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideDate]="true" [endDisabled]="true" (startChange)="manageEnd()" [markChanges]="true"></swe-datetime>
            <swe-element class="d-none d-md-block" style="max-width:70px;" [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(722)" [statusLabel]="0" [(model)]="setting.Hours" (modelChange)="manageEnd()"></swe-element>
            <button class="btn btn-swe me-3 text-nowrap d-none d-md-block mb-3" style="color:black;cursor:unset;" [disabled]="true">
              <span class="d-none d-xl-inline">{{languageService.getItem(722)}}</span>
              <span class="d-xl-none">{{languageService.getItem(722).substring(0,3)}}</span>
            </button>
            <swe-element class="d-none d-md-block" style="max-width:70px;" [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(623)" [statusLabel]="0" [(model)]="setting.Minutes" (modelChange)="manageEnd()"></swe-element>
            <button class="btn btn-swe d-none d-md-block mb-3 text-nowrap" style="color:black;cursor:unset;" [disabled]="true">
              <span class="d-none d-xl-inline">{{languageService.getItem(786)}}</span>
              <span class="d-xl-none">{{languageService.getItem(786).substring(0,3)}}</span>
            </button>

            <div class="d-md-none d-flex row align-items-center">
              <swe-element class="col-6 mt-3" [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(722)" [statusLabel]="0" [(model)]="setting.Hours" (modelChange)="manageEnd()"></swe-element>
              <button class="btn btn-swe col-3 text-nowrap">
                <span>{{languageService.getItem(722).substring(0,3)}}</span>
              </button>
              <swe-element class="col-6" [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(623)" [statusLabel]="0" [(model)]="setting.Minutes" (modelChange)="manageEnd()"></swe-element>
              <button class="btn btn-swe mb-3 col-3 text-nowrap">
                <span>{{languageService.getItem(786).substring(0,3)}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="permissionService.permissions.HasBreaks&&showBreaks">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe bi-cup-hot-fill" [attr.title]="languageService.getItem(785)"></i>
          </div>
          <div class="swe-col-content">
            <swe-breaklist [(model)]="setting.Breaks" (modelChange)="manageEnd()" [alwaysShowStart]="true" (mealbreakChange)="manageMealbreak($event)" [(mealbreak)]="setting.MealBreak" [mealbreakAccess]="permissionService.permissions.MealBreakAccess" [ignoreConstraints]="true" [minStart]="'0001-01-01'" [maxStart]="'0001-01-01'"></swe-breaklist>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="swe-row-icon">
          <div class="swe-col-icon">
            <i class="bi bi-swe-fw bi-swe bi-journal" [attr.title]="languageService.getItem(1121)"></i>
          </div>
          <div class="swe-col-content">
            <swe-element [type]="'System.List'" [label]="languageService.getItem(1017)" [statusLabel]="0" [(model)]="setting.TimeType" [items]="timetypes" [optional]="true"></swe-element>
          </div>
        </div>
      </div>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1012)" [statusLabel]="3" [(model)]="setting.Weekdays" [items]="weekdays" [container]="{isbit:true}"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(1468)" [statusLabel]="3" [(model)]="setting.ExcludeHolidays"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1013)" [statusLabel]="3" [(model)]="setting.WeekNr"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(355)" [statusLabel]="3" [(model)]="setting.Iteration" [items]="iterations"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1014)" [statusLabel]="3" [(model)]="setting.Quantity"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1015)" [statusLabel]="3" [(model)]="setting.Min"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1016)" [statusLabel]="3" [(model)]="setting.Max"></swe-element>
    </swe-card>
  </div>
  <div class="col-md-6 ms-md-2">
    <swe-card [header]="languageService.getItem(395)" [hasRefresh]="false" [open]="false">
      <span swe-rightcommand class="text-white bg-success bi-swe-pull-right rounded"><i class="bi bi-plus-lg bi-swe-fw swe-click" (click)="addActivity($event)"></i></span>
      <ng-container *ngFor="let activity of setting.Activities">

        <swe-card *ngIf="!activity.IsDeleted" [header]="getActivityName(activity.ActivityType)" [open]="activity.IsOpen" [hasRefresh]="false">
          <span swe-rightcommand><i class="bi bi-trash-fill bi-swe-pull-right swe-click" (click)="deleteActivity(activity)"></i></span>
          <div class="row">
            <div class="swe-row-icon">
              <div class="swe-col-icon">
                <i class="bi bi-swe-fw bi-swe bi-clock-fill" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
              </div>
              <div class="swe-col-content d-md-flex">
                <swe-datetime class="me-3" [(start)]="activity.Start" [adjustEnd]="false" [(end)]="activity.End" [labelStart]="languageService.getItem(396)" (startChange)="manageActivityEnd(activity)" (endChange)="manageActivityEnd(activity)" [labelEnd]="languageService.getItem(397)" [hideDate]="true" [markChanges]="true"></swe-datetime>
              </div>
            </div>
          </div>
          <swe-element [type]="'System.List'" [label]="languageService.getItem(1018)" [statusLabel]="2" [(model)]="activity.ActivityType" [items]="generalService.activitytypes"></swe-element>
        </swe-card>
      </ng-container>
    </swe-card>
    <swe-card [header]="languageService.getItem(156)" [hasRefresh]="false" [open]="false">
      <span swe-rightcommand class="bi-swe-pull-right bg-success text-white rounded"><i class="bi bi-swe-fw bi-plus-lg swe-click" (click)="addBookingProfile($event)"></i></span>
      <ng-container *ngFor="let bookingprofile of setting.Properties">
        <swe-card *ngIf="!bookingprofile.IsDeleted" [header]="getPropertyName(bookingprofile.PropertyId)" [open]="bookingprofile.IsOpen" [hasRefresh]="false">
          <span swe-rightcommand><i class="bi bi-trash-fill bi-swe-pull-right swe-click" (click)="deleteBookingProfile(bookingprofile)"></i></span>
          <swe-element [type]="'System.List'" [label]="languageService.getItem(1020)" [statusLabel]="0" [(model)]="bookingprofile.PropertyId" (modelChange)="propertyChosen($event)" [items]="properties"></swe-element>
          <swe-element [(model)]="bookingprofile.Value" [label]="languageService.getItem(1021)" [type]="bookingprofile.Type" [items]="manageReload(bookingprofile)"></swe-element>
        </swe-card>
      </ng-container>
    </swe-card>
  </div>


</div>
