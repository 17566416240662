<swe-pageheader [header]="languageService.getItem(1237)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <!--Data-->
  <div class="col-12 col-lg-4 float-start">
    <swe-articledata [id]="id"></swe-articledata>
  </div>
</div>
