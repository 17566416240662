import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServerEventService {

  //Class members
  private eventSource: EventSource;

  //Contructor
  constructor(
    private zone: NgZone
  ) {

  }

  //Create EventSource instance
  private getEventSource(): EventSource {

    let origin = '';
    if (window.location) {
      origin = window.location.origin;
    }

    return new EventSource(origin + '/api/v1/system/events?ngsw-bypass=true', { withCredentials: true });
  }


  //Connect
  public connect(): Observable<Event> {

    //Close if active
    if (this.eventSource) {
      this.close();
    }

    //Create new instance
    this.eventSource = this.getEventSource();

    return new Observable((subscriber: Subscriber<Event>) => {

      this.eventSource.onerror = error => { console.log('Error SSE: ' + this.eventSource.readyState); };
      this.eventSource.onopen = message => { /*console.log('Open SSE');*/ };
      this.eventSource.addEventListener('ConnectionSuccess', message => { console.log('SSE (ConnectionSuccess)'); });
      this.eventSource.addEventListener('Heartbeat', message => { console.log('SSE (Heartbeat)'); });
      this.eventSource.onmessage = message => {
        //console.log('New SSE');
        this.zone.run(() => subscriber.next(message));
      };
      
    });
  }
  


  //Close
  public close(): void {

    console.log('Close SSE subscriber');

    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }
}

