import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';


@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html'
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _id: number;
  private _userdata: any = {}; //Sharing UserData between components
  private _objecttoobjects: any[] = [];
  private _impersonatemenu: boolean = false;
  private _pincode: string = '';
  private _pinfocus: boolean = false;
  private _tabs: any;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService,
    private listService: ListService,
    private generalService: GeneralService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    
  }

  ngOnDestroy() {
    this.dataService.tokenRequest('/api/v1/users/' + this._id + '/close', 'POST')
      .subscribe(res => {

      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastuser = this._id;

      this.load();
    });
  }

  /*Properties*/
  public get cards() {
    if (this._tabs) {
      return {
        data: this._tabs.data.navigate,
        employmentplans: this._tabs.employmentplans.navigate,
        bookings: this._tabs.bookings.navigate,
        requests: this._tabs.requests.navigate,
        availability: this._tabs.availability.navigate,
        belongings: this._tabs.belongings.navigate,
        statistics: this._tabs.statistics.navigate,
        icalendar: this._tabs.ical.navigate,
        subscriptions: this._tabs.subscriptions.navigate,
        contract: this._tabs.contract.navigate,
        logs: this._tabs.logs.navigate,
        systemlogs: false,
        messagelogs: false,
        objects: this._tabs.objects.map(x => x.navigate)
      };
    }
    else {
      return {
        data: this.settingService.isDevice(['md', 'lg', 'xl', 'xxl']),
        employmentplans: false,
        bookings: false,
        requests: false,
        availability: false,
        belongings: false,
        statistics: false,
        icalendar: false,
        subscriptions: false,
        contract: false,
        logs: false,
        systemlogs: false,
        messagelogs: false
      };
    }
  }
  public get id() {
    return this._id;
  }
  public get userdata() {
    return this._userdata;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }
  public get impersonatemenu() {
    return this._impersonatemenu;
  }
  public set impersonatemenu(val) {
    this._impersonatemenu = val;
  }
  public get pincode() {
    return this._pincode;
  }
  public set pincode(val) {
    this._pincode = val;
  }
  public get pinfocus() {
    return this._pinfocus;
  }
  public set pinfocus(val) {
    this._pinfocus = val;
  }

  public get tabs() {
    return this._tabs;
  }



  /*Methods*/
  public manageUserdata(data) {
    this._userdata = data;
    this.manageActions();
  }
  public toggle() {
    this._impersonatemenu = !this._impersonatemenu;
    this._pinfocus = this._impersonatemenu;
  }
  public keyup(e) {
    if (e.keyCode == 13) {
      this.impersonate();
      this._pinfocus = false;
    }
  }
  public impersonate() {

    this.authenticationService.impersonate(this.id, this._pincode)
      .subscribe((res) => {

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });

          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
      });

    this._pincode = '';
    this._impersonatemenu = false;
  }

  //Functions

  private manageActions() {
    this._tabs = {
      data: {
        icon: 'bi-person-fill',
        label: this.languageService.getItem(32),
        access: true,
        navigate: true,
      },
      employmentplans: {
        icon: 'bi-list-ul',
        label: this.languageService.getItem(698),
        access: this._id > 0 && this.permissionService.permissions.EmploymentPlan && this._userdata.BookingAccess > 0,
        navigate: false,
      },
      bookings: {
        icon: 'bi-list-ul',
        label: this.languageService.getItem(4),
        access: this._id > 0 && !this.permissionService.permissions.HideShifts && this._userdata.BookingAccess > 0,
        navigate: false,
      },
      requests: {
        icon: 'bi-send',
        label: this.languageService.getItem(1077),
        access: this._id > 0 && this._userdata.RequestAccess > 0,
        navigate: false,
      },
      availability: {
        icon: 'bi-calendar-check',
        label: this.languageService.getItem(51),
        access: this._id > 0 && this._userdata.UserMarkedDateAccess > 0,
        navigate: false,
      },
      belongings: {
        icon: 'bi-diagram-2-fill',
        label: this.languageService.getItem(33),
        access: this._id > 0 && this.permissionService.permissions.ShowRoleLevels && this._userdata.Id > -1,
        navigate: false,
      },
      statistics: {
        icon: 'bi-bar-chart',
        label: this.languageService.getItem(241),
        access: this._id > 0 && this._userdata.BookingAccess > 0 && this._userdata.StatisticAccess > 0
          && (this.permissionService.permissions.Standby > 0 || this.permissionService.permissions.Abscent > 0 || this.permissionService.permissions.ReplySms),
        navigate: false,
      },
      ical: {
        icon: 'bi-calendar-plus',
        label: this.languageService.getItem(385),
        access: this._id > 0 && ((this._userdata.ICalUrl && this._userdata.ICalUrl.length > 0) || (this._userdata.ICalLevelUrl && this._userdata.ICalLevelUrl.length > 0)),
        navigate: false,
      },
      subscriptions: {
        icon: 'bi-bell-fill',
        label: this.languageService.getItem(1473),
        access: this._userdata.SubscriptionsAccess > 0,
        navigate: false,
      },
      objects: [],
      contract: {
        icon: 'bi-file-earmark-text-fill',
        label: this.languageService.getItem(953),
        access: this._id > 0 && this._userdata.ContractAccess > 0,
        navigate: false,
      },
      logs: {
        icon: 'bi-clock-history',
        label: this.languageService.getItem(400),
        access: this._id > 0 && this._userdata.LogAccess > 0,
        navigate: false,
      }
  };
    this._objecttoobjects.forEach(obj => {
        this._tabs.objects.push({
          icon: 'bi-bookmark-fill',
          label: obj.Value,
          access: this._id > 0 && this._userdata.Id > -1 && this.permissionService.hasObject(obj.Key, this._id) > 0,
          navigate: false, });
    });
  }
  private load() {
    
    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['User'], 'Type');

  }
}
