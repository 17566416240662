<!--With List-->
<div *ngIf="listutility" class="swe-fill-to-edge" sweFixedHeader [nopadding]="true" [ngClass]="{'swe-reset-sticky-top': resetsticky }" [noleftpadding]="true">
  <div class="card shadow mb-2" [ngClass]="{'d-none': !datePagerOpt&&!listutility.toggleaccess }">
    <div class="card-body px-md-3 row">
      <div class="col-12 ms-2" (click)="toggleAfter()" [ngClass]="{'swe-click': hasTwoRows}">
        <span class="swe-print-remove position-absolute d-inline-flex" style="top:20px;right:8px" *ngIf="hasTwoRows"><a href="javascript:void(0);" class="swe-no-link float-end" [attr.title]="header"><i class="bi bi-swe-fw bi-swe-xs-pull-right" [ngClass]="{'bi-chevron-right': !isOpen,'bi-chevron-down': isOpen}"></i></a></span>
        <div class="btn-group mb-1 me-1" [ngClass]="{'d-none d-md-inline-flex': datePagerOpt}" *ngIf="listutility.toggleaccess">
          <div class="dropdown">
            <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" *ngIf="listutility.toggleaccess" [attr.disabled]="listutility.rows.length==0 ? true : null">
              <span><i class="bi bi-check-lg"></i></span>
            </button>
            <ng-container [ngTemplateOutlet]="listoptions"></ng-container>
          </div>
        </div>
        <div class="d-inline-flex me-1" (click)="$event.stopPropagation();">
          <swe-datepager *ngIf="datePagerOpt" [type]="datePagerOpt.type" (dateChange)="search();listutility.checkall(false);" [disabled]="loading" [hideTime]="datePagerOpt.hideTime"></swe-datepager>
        </div>
        <div class="btn-group d-none d-md-inline-flex align-top me-1"  *ngIf="all">
          <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
        </div>
        <div class="btn-group d-none d-md-inline-flex me-1 align-top" *ngIf="more">
          <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
        </div>
        <div class="d-none d-md-inline-flex me-1 align-top bi-swe-pull-right" *ngIf="extra&&extraPermissions">
          <ng-container [ngTemplateOutlet]="extra"></ng-container>
        </div>
        <div class="d-none d-lg-inline-flex" *ngIf="!scrolling">
          <swe-filteritems></swe-filteritems>
        </div>
      </div>
      <div class="mt-1 col-12 ms-2" *ngIf="isOpen&&hasTwoRows">
        <div *ngIf="datePagerOpt" class="d-inline d-md-none">
          <div class="btn-group d-inline-flex" [ngClass]="{'d-lg-inline-flex d-md-none': datePagerOpt}" *ngIf="listutility.toggleaccess">
            <div class="dropdown">
              <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" *ngIf="listutility.toggleaccess" [attr.disabled]="listutility.rows.length==0 ? true : null">
                <span><i class="bi bi-check-lg"></i></span>
              </button>
              <ng-container [ngTemplateOutlet]="listoptions"></ng-container>
            </div>
          </div>
        </div>
        <div class="btn-group d-inline-flex d-md-none" *ngIf="all">
          <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
        </div>
        <div class="btn-group ms-1 d-inline-flex d-md-none" *ngIf="more">
          <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
        </div>
        <div *ngIf="extra&&extraPermissions" class="d-inline-flex d-md-none ms-1 btn-group">
          <ng-container [ngTemplateOutlet]="extra"></ng-container>
        </div>
        <div *ngIf="after&&datePagerOpt" class="d-inline-flex ms-1">
          <ng-container [ngTemplateOutlet]="after"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Without list-->
<div *ngIf="!listutility" class="swe-fill-to-edge" sweFixedHeader [nopadding]="true" [ngClass]="{'swe-reset-sticky-top': resetsticky}" [noleftpadding]="true">
  <div class="card shadow">
    <div class="card-body px-md-3 pb-1 row">
      <div (click)="toggleAfter()" class="mb-1 col-12 ms-3" [ngClass]="{'swe-click': hasTwoRows}">
        <span class="swe-print-remove position-absolute" style="top:20px;right:8px" *ngIf="hasTwoRows"><a href="javascript:void(0);" class="swe-no-link float-end" [attr.title]="header"><i class="bi bi-swe-fw bi-swe-xs-pull-right" [ngClass]="{'bi-chevron-right': !isOpen,'bi-chevron-down': isOpen}"></i></a></span>
        <div class="me-1 d-inline-flex" (click)="$event.stopPropagation();">
          <swe-datepager *ngIf="datePagerOpt" [type]="datePagerOpt.type" (dateChange)="search();" [disabled]="loading" [hideTime]="datePagerOpt.hideTime"></swe-datepager>
        </div>
        <div [ngClass]="{'d-md-inline d-none': datePagerOpt}">
          <div class="btn-group align-top mx-2" *ngIf="all">
            <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
          </div>
          <div class="btn-group ms-1 align-top" *ngIf="more">
            <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
          </div>
        </div>
        <div class="d-none d-md-inline" *ngIf="extra&&extraPermissions">
          <ng-container [ngTemplateOutlet]="extra"></ng-container>
        </div>
        <div class="mb-1 d-none d-lg-inline">
          <swe-filteritems *ngIf="!scrolling"></swe-filteritems>
        </div>
      </div>

      <div class="mt-1 col-12 ms-3" *ngIf="isOpen&&hasTwoRows">
        <div class="btn-group d-inline-flex d-md-none" *ngIf="all">
          <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
        </div>
        <div class="btn-group ms-1 d-inline-flex d-md-none" *ngIf="more">
          <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
        </div>
        <div class="d-inline-flex d-md-none" *ngIf="extra&&extraPermissions">
          <ng-container [ngTemplateOutlet]="extra"></ng-container>
        </div>
        <div *ngIf="after">
          <ng-container [ngTemplateOutlet]="after"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="position-fixed bottom-0 end-50 start-50 mb-5" *ngIf="canReturnToTop&&showReturnToTop" style="z-index:10000">
  <button class="btn btn-outline-primary text-nowrap" (click)="scrollToTop()">{{languageService.getItem(1495)}} <i class="bi bi-arrow-up"></i></button>
</div>


