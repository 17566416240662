import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { PermissionService } from '../_services/permission.service';
import { AlertService } from '../_services/alert.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';
import { GeneralService } from '../_services/general.service';
import { ListService } from '../_services/list.service';



@Component({
  selector: 'swe-generalrequests',
  templateUrl: './generalrequests.component.html'
})
export class GeneralRequestsComponent implements OnInit, OnChanges {
  @Input() bookingid: number = 0;
  @Input() userid: number = 0;
  @Input() open: boolean;
  @Input() data: any;
  @Input() new: boolean;

  private _requests: any[] = [];
  private _requestgroups: any[] = [];
  private _requestgroupid: number = 0;
  private _requestgroupname: string = '';
  private _addMenu: boolean = false;
  private _groupMenu: boolean = false;
  private _user: any = {};
  private _more: boolean = false;
  private _hits: number = 0;
  private _multiple: number = 1;
  private _loading: boolean = false;
  private _actionmenu: boolean = false;
  private _listutility: ListUtility = new ListUtility();
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 1 ? true : false;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnBookSms == 1 ? true : false;
  

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private generalService: GeneralService,
    private listService: ListService,
    private alertService: AlertService,
    private viewCacheService: ViewCacheService,
    private router: Router
  ) {
    
  }

  ngOnInit() {

    this._listutility.hasdataaccess = false;
    this.init();
  }
  
  ngOnChanges() {
    if (this.open) {
      this.search();
    }
  }
  

  /*Properties*/
  public get requests() {
    return this._requests;
  }
  public get requestgroups() {
    return this._requestgroups;
  }
  public get requestgroupid() {
    return this._requestgroupid;
  } 
  public get requestgroupname() {
    return this._requestgroupname;
  }
  public get addMenu() {
    return this._addMenu;
  }
  public set addMenu(val) {
    this._addMenu = val;
  }
  public get groupMenu() {
    return this._groupMenu;
  }
  public set groupMenu(val) {
    this._groupMenu = val;
  }
  public get more(): boolean {
    return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get listutility() {
    return this._listutility;
  }
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }




  /*Methods*/
  public changegroup(id, e) {

    this._requestgroupid = id;

    let group = this.listService.find(this._requestgroups, 'Id', id);
    if (group != null) {
      this._requestgroupname = group.Name;
    }

    this.search(false, e);

    this._groupMenu = false;
  }
  public toggleSubscribe(group, e) {

    e.stopPropagation();

    group.Subscribe = !group.Subscribe;

    this.dataService.tokenRequest('/api/v1/requests/groups/subscription/' + this.userid, 'PUT', group, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });

          this.search(false, e);

          this._groupMenu = false;
        }
      });

  }
  public search(getmore: boolean = false, e = null) {

    if (e) {
      e.stopPropagation();
    }

    this._more = false;

    let top = 25;
    
    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._requests = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      UserId: this.userid,
      BookingId: this.bookingid,
      RequestGroupId: this._requestgroupid,
      Start: this.userid > 0 ? this.settingService.start('booking') : this.data.Created,
      End: this.userid > 0 ? this.settingService.end('booking') : new Date(2100, 0, 1)
    };


    this._loading = true;

    this.dataService.tokenRequest('/api/v1/requests/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Users.forEach((request) => {
            this._requests.push(request);
          });
          
          this._listutility.rows = this._requests;
          this._hits = this._requests.length;
          this._more = res.More;
          
          this._loading = false;
        }
      });
  }
  public answer(requestuserid: number, optionid: number, e) {

    e.stopPropagation();

    let dto = {
      Id: requestuserid,
      Option: optionid,
      ManualNotifyEmail: this._checknotificationemail,
      ManualNotifySms: this._checknotificationsms
    }

    this.dataService.tokenRequest('/api/v1/requests/response', 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });
          this.search();
        }
        
      });
  }
  public goto(id: number, group: number, e) {

    e.stopPropagation();

    if (id > 0) {
      this.router.navigate(['/requests', id]);
    }
    else if (this.userid > 0) {
      this._user['Id'] = this.userid;
      this.viewCacheService.add('request_users', [this._user]);

      let requestuser = this.listService.find(this.data['RequestUserIdList'], 'Key', group);
      
      if (requestuser) {
        this.router.navigate(['/requests', requestuser.Value]);
      }
      else if (group > 0) {
        this.router.navigate(['/requests/send/1/group/' + group]);
      }
      else { 
        this.router.navigate(['/requests/send/1']);
      }
    }
  }
  




  //Functions
  private init() {

    if (this.userid > 0) {

      if (this.userid == this.permissionService.user.Id) {
        //You never can't create request on your self
        this.new = false;
      }

      this._requestgroups = [];
      this.dataService.tokenRequest('/api/v1/requests/groups', 'GET', {})
        .subscribe((res) => {

          this._requestgroups = res;
        });

      this.dataService.tokenRequest('/api/v1/users/' + this.userid + '/small', 'GET')
        .subscribe((res) => {

          this._user = res;
        });
    }

  }


}
