import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { GoogleMapsModule } from '@angular/google-maps';

//App
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth.guard';
import { RedirectComponent } from './_guards/redirect.component';
import { AuthenticationInterceptor } from './_interceptors/authentication.interceptor';
//Services
import { GeneralService } from './_services/general.service';
import { LanguageService } from './_services/language.service';
import { PermissionService } from './_services/permission.service';
import { PropertyService } from './_services/property.service';
//Nav
import { TopMenuComponent } from './nav/topmenu.component';
import { BotMenuComponent } from './nav/botmenu.component';
import { SidebarComponent } from './nav/sidebar.component';
import { SearchbarComponent } from './nav/searchbar.component';
import { NotificationComponent } from './notification/notification.component';
//Components
import { TemplateComponent } from './template/template.component';
import { AdminLoginComponent } from './login/adminlogin.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './login/registration.component';
import { LostPasswordComponent } from './login/lostpassword.component';
import { TimeClockComponent } from './public/timeclock.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { HomeNewsComponent } from './home/details/homenews.component';
import { HomeBookingsComponent } from './home/details/homebookings.component';
import { HomeCheckInComponent } from './home/details/homecheckin.component';
import { HomeShortcutsComponent } from './home/details/homeshortcuts.component';
import { HomeStatisticsComponent } from './home/details/homestatistics.component';
import { HomeChartComponent } from './home/details/homechart.component';
import { UsersComponent } from './users/users.component';
import { MultiUsersComponent } from './users/action/multiusers.component';
import { UserDetailComponent } from './users/userdetail.component';
import { UserDataComponent } from './users/details/userdata.component';
import { UserBookingsComponent } from './users/details/userbookings.component';
import { UserBelongingsComponent } from './users/details/userbelongings.component';
import { UserAvailabilityComponent } from './users/details/useravailability.component';
import { UserStatisticsComponent } from './users/details/userstatistics.component';
import { UserICalendarComponent } from './users/details/usericalendar.component';
import { UserSubscriptionsComponent } from './users/details/usersubscriptions.component';
import { UserContractComponent } from './users/details/usercontract.component';
import { UserTimeBankComponent } from './users/details/usertimebank.component';
import { UserLasComponent } from './users/details/userlas.component';
import { UserEmploymentsComponent } from './users/details/useremployments.component';
import { UserMarkedDatesComponent } from './usermarkeddates/usermarkeddates.component';
import { MultiUserMarkedDatesComponent } from './usermarkeddates/action/multiusermarkeddates.component';
import { UserMarkedDateDetailComponent } from './usermarkeddates/usermarkeddatedetail.component';
import { UserMarkedDateDataComponent } from './usermarkeddates/details/usermarkeddatedata.component';
import { BookingsComponent } from './bookings/bookings.component';
import { MultiBookingsComponent } from './bookings/action/multibookings.component';
import { CopyBookingsComponent } from './bookings/action/copybookings.component';
import { BookingDetailComponent } from './bookings/bookingdetail.component';
import { BookingDataComponent } from './bookings/details/bookingdata.component';
import { BookingUsersComponent } from './bookings/details/bookingusers.component';
import { BookingAvailableUsersComponent } from './bookings/details/bookingavailableusers.component';
import { BookingWizardComponent } from './bookings/bookingwizard.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineGridComponent } from './timeline/grid/timelinegrid.component';
import { TimelineHeaderComponent } from './timeline/grid/timelineheader.component';
import { TimelineRowComponent } from './timeline/grid/timelinerow.component';
import { TimereportsComponent } from './timereports/timereports.component';
import { MultiTimereportsComponent } from './timereports/action/multitimereports.component';
import { TimereportDetailComponent } from './timereports/timereportdetail.component';
import { TimereportDataComponent } from './timereports/details/timereportdata.component';
import { AbscentDataComponent } from './timereports/details/abscentdata.component';
import { LevelsComponent } from './levels/levels.component';
import { MultiLevelsComponent } from './levels/action/multilevels.component';
import { LevelDetailComponent } from './levels/leveldetail.component';
import { LevelDataComponent } from './levels/details/leveldata.component';
import { LevelSettingsComponent } from './levels/details/levelsettings.component';
import { LevelRelationsComponent } from './levels/details/levelrelations.component';
import { LevelUsersComponent } from './levels/details/levelusers.component';
import { LevelBookingsComponent } from './levels/details/levelbookings.component';
import { ObjectsComponent } from './objects/objects.component';
import { ObjectDetailComponent } from './objects/objectdetail.component';
import { ObjectDataComponent } from './objects/details/objectdata.component';
import { ObjectToObjectDetailComponent } from './objecttoobjects/objecttoobjectdetail.component';
import { ObjectToObjectDataComponent } from './objecttoobjects/details/objecttoobjectdata.component';
import { MultiObjectsComponent } from './objects/action/multiobjects.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportCellComponent } from './reports/table/reportcell.component';
import { MessagesComponent } from './messages/messages.component';
import { MessageSendComponent } from './messages/messagesend.component';
import { MessageDetailComponent } from './messages/messagedetail.component';
import { MessageDataComponent } from './messages/details/messagedata.component';
import { MessageReceiversComponent } from './messages/details/messagereceivers.component';
import { LogsComponent } from './logs/logs.component';
import { RequestDetailComponent } from './requests/requestdetail.component';
import { RequestDataComponent } from './requests/details/requestdata.component';
import { RequestSendComponent } from './requests/requestsend.component';
import { SmsStatisticsComponent } from './messages/smsstatistics.component';
import { GuideComponent } from './guide/guide.component';
//Admin
import { ImpersonateComponent } from './admin/impersonate/impersonate.component';
import { ImportComponent } from './admin/import/import.component';
import { ReportArchiveComponent } from './admin/reports/reportarchive.component';
import { AdminPropertiesComponent } from './admin/properties/adminproperties.component';
import { AdminPropertyDetailComponent } from './admin/properties/adminpropertydetail.component';
import { HolidaysComponent } from './admin/holidays/holidays.component';
import { HolidayDetailComponent } from './admin/holidays/holidaydetail.component';
import { NewsComponent } from './admin/news/news.component';
import { NewsDetailComponent } from './admin/news/newsdetail.component';
import { OBRulesComponent } from './admin/obrules/obrules.component';
import { OBRuleDetailComponent } from './admin/obrules/obruledetail.component';
import { MultiObRulesComponent } from './admin/obrules/action/multiobrules.component';
import { ContractsComponent } from './admin/contracts/contracts.component';
import { ContractDetailComponent } from './admin/contracts/contractdetail.component';
import { ContractDataComponent } from './admin/contracts/details/contractdata.component';
import { ContractArticlesComponent } from './admin/contracts/details/contractarticles.component';
import { ContractOBRulesComponent } from './admin/contracts/details/contractobrules.component';
import { ContractPricesComponent } from './admin/contracts/details/contractprices.component';
import { ContractTimeRulesComponent } from './admin/contracts/details/contracttimerules.component';
import { ContractTimeBankComponent } from './admin/contracts/details/contracttimebank.component';
import { ContractUsersComponent } from './admin/contracts/details/contractusers.component';
import { ContractLasComponent } from './admin/contracts/details/contractlas.component';
import { EmploymentsComponent } from './admin/employments/employments.component';
import { ArticlesComponent } from './admin/articles/articles.component';
import { ArticleDetailComponent } from './admin/articles/articledetail.component';
import { ArticleDataComponent } from './admin/articles/details/articledata.component';
import { IntegrationsComponent } from './admin/integrations/integrations.component';
import { ExternalProvidersComponent } from './admin/externalproviders/externalproviders.component';
import { TemplatesComponent } from './admin/templates/templates.component';
import { TemplateDetailComponent } from './admin/templates/templatedetail.component';
import { TemplateSettingDataComponent } from './admin/templates/templatesettingdata.component';
import { EconomyComponent } from './admin/economy/economy.component';
import { EconomySettingsComponent } from './admin/economy/details/economysettings.component';
import { EconomyStatisticsComponent } from './admin/economy/details/economystatistics.component';
import { GuidesComponent } from './admin/guides/guides.component';
import { GuideDetailComponent } from './admin/guides/guidedetail.component';
import { GuideDataComponent } from './admin/guides/details/guidedata.component';
import { GuidePreviewComponent } from './admin/guides/details/guidepreview.component';
//SystemAdmin
import { SystemTablesComponent } from './systemadmin/systemtables/systemtables.component';
import { SystemSettingsComponent } from './systemadmin/systemsettings/systemsettings.component';
import { LogSettingsComponent } from './systemadmin/logsettings/logsettings.component';
import { TimeRulesComponent } from './systemadmin/timerules/timerules.component';
import { TimeRuleDetailComponent } from './systemadmin/timerules/timeruledetail.component';
import { SystemRulesComponent } from './systemadmin/systemrules/systemrules.component';
import { SystemRuleDetailComponent } from './systemadmin/systemrules/systemruledetail.component';
//General details
import { GeneralLogsComponent } from './details/generallogs.component';
import { GeneralMessagesComponent } from './details/generalmessages.component';
import { ObjectToObjectsComponent } from './details/objecttoobjects.component';
import { GeneralRequestsComponent } from './details/generalrequests.component';
import { GeneralArticlesComponent } from './details/generalarticles.component';
import { ShiftsChartComponent } from './details/shiftschart.component';
//Layouts
import { CardComponent } from './layout/card.component';
import { FilterCardComponent } from './layout/filtercard.component';
import { ProfileComponent } from './layout/profile.component';
import { ElementComponent } from './layout/element.component';
import { DatePagerComponent } from './layout/datepager.component';
import { PageHeaderComponent } from './layout/pageheader.component';
import { ListComponent } from './layout/list.component';
import { TableComponent } from './layout/table.component';
import { FixedHeaderComponent } from './layout/fixedheader.component'
//Elements
import { RadioComponent } from './element/radio.component';
import { CheckboxComponent } from './element/checkbox.component';
import { CheckboxTriStateComponent } from './element/checkboxtristate.component';
import { MultipleComponent } from './element/multiple.component';
import { DateTimeComponent } from './element/datetime.component';
import { DatePickerComponent } from './element/datepicker.component';
import { TimePickerComponent } from './element/timepicker.component';
import { LevelSearchComponent } from './element/levelsearch.component';
import { FilterItemsComponent } from './element/filteritems.component';
import { UserSearchComponent } from './element/usersearch.component';
import { BreakComponent } from './element/break.component';
import { BreakListComponent } from './element/breaklist.component';
import { CalendarComponent } from './element/calendar.component';
import { DocumentComponent } from './element/document.component';
import { ConfirmComponent } from './element/confirm.component';
import { ConfirmSerieComponent } from './element/confirmserie.component';
import { ColorComponent } from './element/color.component';
import { PropertiesComponent } from './element/properties.component';
import { InfoTextComponent } from './element/infotext.component';
import { HistoryComponent } from './element/history.component';
import { MapsComponent } from './element/maps.component';
import { AdminRuleComponent } from './element/adminrule.component';
import { PopUpComponent } from './element/popup.component';
import { ContractSearchComponent } from './element/contractsearch.component';
import { DropdownComponent } from './element/dropdown.component';
import { FilteredMultipleComponent } from './element/filteredmultiple.component';
import { FilteredCheckboxComponent } from './element/filteredcheckbox.component';
import { RichTextEditorComponent } from './element/richtexteditor.component'; 
//Directives
import { ImageDirective } from './_directives/image.directive';
import { FixedHeaderDirective } from './_directives/fixedheader.directive';
//ServicesWorker
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ContractlevelsComponent } from './admin/contracts/details/contractlevels.component';


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    TopMenuComponent,
    BotMenuComponent,
    SidebarComponent,
    SearchbarComponent,
    NotificationComponent,
    TemplateComponent,
    AdminLoginComponent,
    LoginComponent,
    RegistrationComponent,
    LostPasswordComponent,
    TimeClockComponent,
    HelpComponent,
    HomeComponent,
    HomeNewsComponent,
    HomeBookingsComponent,
    HomeCheckInComponent,
    HomeShortcutsComponent,
    HomeStatisticsComponent,
    HomeChartComponent,
    UsersComponent,
    MultiUsersComponent,
    UserDetailComponent,
    UserDataComponent,
    UserBookingsComponent,
    UserBelongingsComponent,
    UserAvailabilityComponent,
    UserStatisticsComponent,
    UserICalendarComponent,
    UserSubscriptionsComponent,
    UserContractComponent,
    UserTimeBankComponent,
    UserLasComponent,
    UserEmploymentsComponent,
    UserMarkedDatesComponent,
    MultiUserMarkedDatesComponent,
    UserMarkedDateDetailComponent,
    UserMarkedDateDataComponent,
    BookingsComponent,
    MultiBookingsComponent,
    CopyBookingsComponent,
    BookingDetailComponent,
    BookingDataComponent,
    BookingUsersComponent,
    BookingAvailableUsersComponent,
    BookingWizardComponent,
    TimelineComponent,
    TimelineGridComponent,
    TimelineHeaderComponent,
    TimelineRowComponent,
    TimereportsComponent,
    MultiTimereportsComponent,
    TimereportDetailComponent,
    TimereportDataComponent,
    AbscentDataComponent,
    LevelsComponent,
    MultiLevelsComponent,
    LevelDetailComponent,
    LevelDataComponent,
    LevelSettingsComponent,
    LevelRelationsComponent,
    LevelUsersComponent,
    LevelBookingsComponent,
    ObjectsComponent,
    ObjectDetailComponent,
    ObjectDataComponent,
    ObjectToObjectDetailComponent,
    ObjectToObjectDataComponent,
    MultiObjectsComponent,
    ReportsComponent,
    ReportCellComponent,
    MessagesComponent,
    MessageSendComponent,
    MessageDetailComponent,
    MessageDataComponent,
    MessageReceiversComponent,
    LogsComponent,
    RequestDetailComponent,
    RequestDataComponent,
    RequestSendComponent,
    SmsStatisticsComponent,
    GuideComponent,
    //Admin
    ImpersonateComponent,
    ImportComponent,
    ReportArchiveComponent,
    AdminPropertiesComponent,
    AdminPropertyDetailComponent,
    HolidaysComponent,
    HolidayDetailComponent,
    NewsComponent,
    NewsDetailComponent,
    OBRulesComponent,
    OBRuleDetailComponent,
    MultiObRulesComponent,
    ContractsComponent,
    ContractDetailComponent,
    ContractDataComponent,
    ContractArticlesComponent,
    ContractOBRulesComponent,
    ContractPricesComponent,
    ContractTimeRulesComponent,
    ContractTimeBankComponent,
    ContractUsersComponent,
    ContractLasComponent,
    EmploymentsComponent,
    ArticlesComponent,
    ArticleDetailComponent,
    ArticleDataComponent,
    IntegrationsComponent,
    ExternalProvidersComponent,
    TemplatesComponent,
    TemplateDetailComponent,
    TemplateSettingDataComponent,
    EconomyComponent,
    EconomySettingsComponent,
    EconomyStatisticsComponent,
    GuidesComponent,
    GuideDetailComponent,
    GuideDataComponent,
    GuidePreviewComponent,
    //SystemAdmin
    SystemTablesComponent,
    SystemSettingsComponent,
    LogSettingsComponent,
    TimeRulesComponent,
    TimeRuleDetailComponent,
    SystemRulesComponent,
    SystemRuleDetailComponent,
    //General
    GeneralLogsComponent,
    GeneralMessagesComponent,
    ObjectToObjectsComponent,
    GeneralRequestsComponent,
    GeneralArticlesComponent,
    ShiftsChartComponent,
    //Layouts
    CardComponent,
    FilterCardComponent,
    ProfileComponent,
    ElementComponent,
    DatePagerComponent,
    PageHeaderComponent,
    ListComponent,
    TableComponent,
    FixedHeaderComponent,
    //Elements
    RadioComponent,
    CheckboxComponent,
    CheckboxTriStateComponent,
    MultipleComponent,
    DateTimeComponent,
    DatePickerComponent,
    TimePickerComponent,
    LevelSearchComponent,
    FilterItemsComponent,
    UserSearchComponent,
    BreakComponent,
    BreakListComponent,
    CalendarComponent,
    DocumentComponent,
    ConfirmComponent,
    ConfirmSerieComponent,
    ColorComponent,
    PropertiesComponent,
    InfoTextComponent,
    HistoryComponent,
    MapsComponent,
    AdminRuleComponent,
    PopUpComponent,
    ContractSearchComponent,
    DropdownComponent,
    FilteredMultipleComponent,
    FilteredCheckboxComponent,
    RichTextEditorComponent,
    //Directives
    ImageDirective,
    FixedHeaderDirective,
    ContractlevelsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: 'template', component: TemplateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'adminlogin', component: AdminLoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'registration', component: RegistrationComponent, resolve: { propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'lostpassword', component: LostPasswordComponent, data: { useHistory: true } },
      { path: 'lostpassword/:key', component: LostPasswordComponent, data: { useHistory: true } },
      { path: 'timeclock', component: TimeClockComponent },
      { path: 'help', component: HelpComponent, canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'users', component: UsersComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'users/:id', component: UserDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'users/action/multi', component: MultiUsersComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'usermarkeddates', component: UserMarkedDatesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'usermarkeddates/:id', component: UserMarkedDateDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'usermarkeddates/:id/redirect/:redirect', component: UserMarkedDateDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'usermarkeddates/:id/user/:userid', component: UserMarkedDateDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'usermarkeddates/action/multi', component: MultiUserMarkedDatesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings', component: BookingsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'bookings/:id', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/:id/employmentplan/:employmentplan', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/:id/user/:userid', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/:id/user/:userid/employmentplan/:employmentplan', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/:id/level/:levelid', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/:id/level/:levelid/employmentplan/:employmentplan', component: BookingDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/action/multi', component: MultiBookingsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/action/copy', component: CopyBookingsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/wizard/:id', component: BookingWizardComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/wizard/:id/user/:userid', component: BookingWizardComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'bookings/wizard/:id/level/:levelid', component: BookingWizardComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'timeline', component: TimelineComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'timereports', component: TimereportsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'timereports/:id', component: TimereportDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'timereports/:id/user/:userid/type/:type', component: TimereportDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'timereports/action/multi', component: MultiTimereportsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'levels', component: LevelsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'levels/:id', component: LevelDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'levels/action/multi', component: MultiLevelsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'objects/:category', component: ObjectsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'objects/:category/:id', component: ObjectDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'objects/action/multi/:category', component: MultiObjectsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'objecttoobjects/:category/:general/:id', component: ObjectToObjectDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'reports', component: ReportsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'reports/:id', component: ReportsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'messages', component: MessagesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'messages/send/:type', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/send/:type/resend/:resend', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/send/:type/id/:id', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/send/:type/id/:id/serie/:serie', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/send/:type/id/:id/serie/:serie/body/:body', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/send/:type/id/:id/serie/:serie/body/:body/subject/:subject', component: MessageSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'messages/:category/:id', component: MessageDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'logs', component: LogsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'requests/:id', component: RequestDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/:id/response/:responseid', component: RequestDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type/group/:group', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type/id/:id', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type/id/:id/serie/:serie', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type/id/:id/serie/:serie/body/:body', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'requests/send/:type/id/:id/serie/:serie/body/:body/subject/:subject', component: RequestSendComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/impersonate', component: ImpersonateComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/news', component: NewsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/news/:id', component: NewsDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/import', component: ImportComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/reportarchive', component: ReportArchiveComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/properties', component: AdminPropertiesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/properties/:id', component: AdminPropertyDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/properties/:id/category/:category', component: AdminPropertyDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/holidays', component: HolidaysComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/holidays/:id', component: HolidayDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/overtime', component: OBRulesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/overtime/:id', component: OBRuleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/overtime/action/multi', component: MultiObRulesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },

      { path: 'admin/overtime/:id/contract/:contractId', component: OBRuleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/contracts', component: ContractsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/contracts/:id', component: ContractDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/employments', component: EmploymentsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/articles', component: ArticlesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/articles/:id', component: ArticleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/integrations', component: IntegrationsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/externalproviders', component: ExternalProvidersComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/templates', component: TemplatesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/templates/:id', component: TemplateDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/templates/:id/parent/:parent', component: TemplateDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/economy', component: EconomyComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'admin/guides', component: GuidesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'admin/guides/:id', component: GuideDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'systemadmin/tables', component: SystemTablesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'systemadmin/settings', component: SystemSettingsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'systemadmin/logsettings', component: LogSettingsComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'systemadmin/timerules', component: TimeRulesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'systemadmin/timerules/:id', component: TimeRuleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'systemadmin/timerules/:id/contract/:contractId', component: TimeRuleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'systemadmin/rules', component: SystemRulesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService } },
      { path: 'systemadmin/rules/:id', component: SystemRuleDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true } },
      { path: 'systemadmin/guides', component: GuidesComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true, systemAdmin: true } },
      { path: 'systemadmin/guides/:id', component: GuideDetailComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: { permissiondata: PermissionService, generaldata: GeneralService, propertydata: PropertyService }, data: { useHistory: true, systemAdmin: true } },

      { path: '', component: RedirectComponent, pathMatch: 'full', resolve: { permissiondata: PermissionService } },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]),
    NgChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    GoogleMapsModule
    //AgmCoreModule.forRoot({
    //  apiKey: 'AIzaSyBIGv828yGdy88jj9ESOwdlFI7x34ZELvI',
    //  libraries: ['places']
    //})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: languageProviderFactory, deps: [LanguageService], multi: true },
    DatePipe,
    ThemeService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function languageProviderFactory(provider: LanguageService) {
  return () => provider.loadLanguages();
}
