import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { PermissionService } from '../_services/permission.service';
import { ListService } from '../_services/list.service';
import { DateTimeService } from '../_services/datetime.service';
import { SettingService } from '../_services/setting.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { AlertService } from '../_services/alert.service';
import { PropertyService } from '../_services/property.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html'
})
export class BookingsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _bookings = [];
  private _groupedby = {};
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;
  private _actionmenu: boolean = false;
  private _listutility: ListUtility = new ListUtility();
  //Details
  private _detail: any = {};
  private _detailprofileready: boolean = false;

  constructor(public languageService: LanguageService, public permissionService: PermissionService, private dataService: DataService, private dateTimeService: DateTimeService, private listService: ListService, public settingService: SettingService, private viewCacheService: ViewCacheService, private alertService: AlertService, private propertyService: PropertyService, private router: Router) {

    settingService.initView(
      1 | 2 | 16 | 512,
      ['Booking', 'User', 'Level'],
      [{ type: 'booking', key: 'activityoption' }]
    );

    this._subscriptions.push(settingService.onRefresh$
      .subscribe(() => {
        this.search();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {

    if (this.permissionService.permissions.Bookings == 0) {
      this.router.navigate(['/']);
    }

    this._listutility.toggleaccess = (this.permissionService.permissions.CopyShift > 0 || this.permissionService.permissions.MultiShift > 0 || this.permissionService.permissions.ListSms > 0);

    this.search();
  }

  /*Properties*/
  public get groupedby() {
    return this._groupedby;
  }
  public get bookings() {
    return this._bookings;
  }
  public get more() : boolean {
    return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get detail() {
    return this._detail;
  }
  public get detailprofileready() {
    return this._detailprofileready;
  }
  public get listutility() {
    return this._listutility;
  }


  public handleSearchEvent($event) {
    this.search($event.getMore);
  }

  /*Methods*/
  public search(getmore: boolean = false) {

    if (!this.dateTimeService.validation(this.settingService.booking.start, this.settingService.booking.end)) { return; }

    this._more = false;

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._bookings = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Bookings
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      StatusList: this.settingService.booking.status,
      Personal: this.settingService.booking.personal,
      ShiftType: this.settingService.booking.shifttype,
      Reservation: this.settingService.booking.reservation,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      CheckIn: this.settingService.booking.checkin,
      TypeList: this.settingService.booking.bookingtype.join(),
      OwnerId: this.settingService.booking.owner,
      ActivityTypes: this.settingService.booking.activitytypes,
      TimeTypes: this.settingService.booking.timetypes,
      ShiftLevelGroups: this.settingService.booking.levelgroups.join(),
      ShiftLevelList: this.settingService.levelList(2),
      OnlyParentShiftLevelList: this.settingService.levelList(2, true),

      //Users
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      OnlyParentUserLevelList: this.settingService.levelList(1, true),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor,
      ExcludeEmptyShiftWhenBookingTypeFiltrering: this.settingService.booking.excludeemptyshiftbookingtype == 1 ? true : false,
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookings/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Bookings.forEach(booking => {

            let start = new Date(booking.Start);
            let end = new Date(booking.End);

            booking.dateheader = this.dateTimeService.header(start, end, true);

            this._bookings.push(booking);
          });

          this._listutility.rows = this._bookings;
          this._hits = this._bookings.length;
          this._more = res.More;

          this._groupedby = this.listService.groupByDate(this._bookings, "Start");
        }

        this._loading = false;
      });
  }
  public open(id: number, e) {
    let url = '/bookings/';
    if (id == 0 && this.permissionService.permissions.ShiftWizard) {
      url += 'wizard/';
    }
    url += id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public send(type, serie, e) {

    let bookings = [];
    this._bookings.forEach((booking) => {

      if (booking.checked && booking.Access > 1) {
        bookings.push(booking.Id);
      }

    });

    if (bookings.length > 0) {

      let msg = {
        Auto: true,
        Type: type,
        Serie: serie
      };

      let searchavailable = Math.pow(2, this.permissionService.permissions.SearchAvailable);

      let filter = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        Available: ((this.permissionService.permissions.AvailableType & searchavailable) == searchavailable) ? this.permissionService.permissions.SearchAvailable : 0
      };

      let bookingmsg = {
        BookingList: bookings,
        Message: msg,
        Filter: filter
      };

      this.dataService.tokenRequest('/api/v1/bookings/message', 'POST', bookingmsg, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.search(false);
        });
    }

    this._actionmenu = false;

    e.stopPropagation();
  }
  public action(option) {

    let bookings = [];
    this._bookings.forEach((booking) => {
      if (booking.checked && booking.Access > 1) {
        bookings.push(booking.Id);
      }
    });

    if (bookings.length > 0) {

      this.viewCacheService.add('multi_bookings', bookings);

      this.router.navigate(['/bookings/action/' + option + '/']);
    }
  }


  
  //Details
  public showdetail(booking, e) {

    e.stopPropagation();

    this.resetdetail(e);

    this._detail.row = booking.Id;
    this._detail.width = 400;
    this._detail.booking = booking;

    /*Detail Header*/
    let headeritems = 1; //Icons and dateheader
    if (!this.permissionService.permissions.HideLevels) {
      headeritems++; //Levels
    }
    headeritems++; //Header
    if (!this.permissionService.permissions.HideAmount || this._detail.booking.Serie > 0) {
      headeritems++; //Amount or Serie
    }
    /**************/

    this._detailprofileready = false;

    this.dataService.tokenRequest('/api/v1/bookings/detail/' + booking.Id, 'GET', {})
      .subscribe((res) => {

        let extraitems = 1; //Block-Header
        extraitems++; //Bottom-Margin

        let profileheight = (21 * headeritems) + (23 * (res.Profile.length + extraitems));
        if (this._detail.height < profileheight) {
          this._detail.height = profileheight;
        }

        res.Profile.forEach((profile) => {
          let property = this.propertyService.getProperty(profile.Property);
          if (property) {
            profile.Type = property.Type;
            if (property.Type == 'System.DateTime' || property.Type == 'System.Date' || property.Type == 'System.Time') {
              profile.Value = this.dateTimeService.format(profile.Value, property.Format);
            }
            else if (property.Type == 'System.List' || property.Type == 'System.MultiList') {
              if (profile.Value != null && profile.Value.length > 0) {
                let res = '';
                profile.Value.split('|').forEach((val) => {

                  property.Items.forEach((item) => {
                    if (item.Id == val) {

                      if (res.length > 0) {
                        res += ' | ';
                      }

                      res += item.Name;
                    }
                  });
                });
                profile.Value = res;
              }
            }
          }
        });
        this._detail.profile = res.Profile;

        this._detailprofileready = true;
      });

    this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id + '/users', 'Get', {})
      .subscribe((res) => {

        this._detail.users = this.listService.groupByInt(
          res.Users,
          'Type',
          [
            { Id: 1, Name: this.languageService.getItem(30), Sort: 0 },
            { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
            { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
            { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
            { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
          ]);

        let userlength = Object.keys(this._detail.users).length;

        let extraitems = userlength; //Block-Header 
        extraitems += userlength; //Bottom-Margin

        let userheight = (20 * headeritems) + (30 * (res.Users.length + extraitems));
        if (this._detail.height < userheight) {
          this._detail.height = userheight;
        }
      });

    return false;
  }
  public resetdetail(e) {

    e.stopPropagation();
    
    this._detail = {
      row: -1,
      left: 0,
      top: 0,
      height: 0,
      width: 0,
      booking: {},
      profile: [],
      users: []
    };

    return false;
  }
}
