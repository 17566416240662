<swe-pageheader [header]="languageService.getItem(113)"></swe-pageheader>
<div>
  <input class="form-control mr-sm-2" [(ngModel)]="search" (ngModelChange)="onSearch()" type="search" [placeholder]="languageService.getItem(13)" aria-label="languageService.getItem(13)">
  <div class="dropdown">
    <button class="btn btn-swe" type="button" (click)="encryptionmenu=!encryptionmenu">
      <span>{{encryptiontext}}&nbsp;<i class="bi bi-caret-down-fill"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': encryptionmenu}">
      <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(1496)" name="encryptionoptions" [value]="0" [(ngModel)]="encryption" (ngModelChange)="changeencryption()" />&nbsp;{{languageService.getItem(1496)}}</label></li>
      <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(1497)" name="encryptionoptions" [value]="1" [(ngModel)]="encryption" (ngModelChange)="changeencryption()" />&nbsp;{{languageService.getItem(1497)}}</label></li>
      <li class="dropdown-item swe-click"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(1498)" name="encryptionoptions" [value]="2" [(ngModel)]="encryption" (ngModelChange)="changeencryption()" />&nbsp;{{languageService.getItem(1498)}}</label></li>
    </ul>
  </div>
</div>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start" *ngFor="let group of settings">
    <swe-card [header]="group.Header" [(open)]="group.Open" [hasCollapse]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save()">
      <span swe-rightcommand>
        <i class="bi-swe-pull-right bi swe-click bi-alphabet-uppercase bi-swe-fw" [ngClass]="{'text-primary': showReadableName}" (click)="toggleReadable($event)"></i>
      </span>
      <ng-container *ngFor="let setting of group.Settings" [ngSwitch]="setting.Type">
        <div [ngClass]="{'bg-danger p-1': setting.NeedEncryption && !setting.Encryption}" [attr.title]="(setting.NeedEncryption && !setting.Encryption)?languageService.getItem(1499):''">
          <i class="bi bi-swe-fw bi-swe bi-swe-pull-right swe-click" [ngClass]="{'bi-question-circle-fill': !showhelp, 'bi-x-lg':showhelp}" (click)="setting.showhelp=!setting.showhelp" [attr.title]="title" *ngIf="setting.Description"></i>
          <i class="bi bi-swe-fw bi-swe bi-swe-pull-right swe-click" [ngClass]="{'bi-unlock': !setting.Encryption, 'bi-lock-fill':setting.Encryption}" (click)="setting.Encryption=!setting.Encryption;setting.Changed=true"></i>
          <div class="swe-info-text mt-2" *ngIf="setting.showhelp">
            <p>{{setting.Description}}</p>
          </div>
          <swe-element [type]="'System.List'" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchCase="'System.List'"></swe-element>
          <swe-element [type]="'System.MultiList'" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchCase="'System.MultiList'"></swe-element>
          <swe-element [type]="setting.Type" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchDefault></swe-element>
        </div>
      </ng-container>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
