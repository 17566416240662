<swe-pageheader [header]="languageService.getItem(478)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0, category)" *ngIf="id>0&&permissionService.user.IsSuper"></i>
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <!--General data-->
  <div class="col-12 col-md-4 float-start">
    <swe-card [header]="languageService.getItem(479)" [open]="true" [hasRefresh]="false" [hasSave]="((property.Public>0&&property.Access>1)||permissionService.user.IsSuper)" (saveChange)="save()">
      <div *ngIf="permissionService.user.IsSuper">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(517)" [statusLabel]="2" [(model)]="property.Category" [items]="categories"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[0]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[0], 'bi-square': !publicaccess[0]}" (click)="publicaccess[0]=!publicaccess[0]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(480)" [statusLabel]="2" [(model)]="property.Name"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[8]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[8], 'bi-square': !publicaccess[8]}" (click)="publicaccess[8]=!publicaccess[8]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(490)" [statusLabel]="2" [(model)]="property.Sort"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[25]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[25], 'bi-square': !publicaccess[25]}" (click)="publicaccess[25]=!publicaccess[25]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.TextArea'" [label]="languageService.getItem(481)" [statusLabel]="2" [(model)]="property.Comment"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[1]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[1], 'bi-square': !publicaccess[1]}" (click)="publicaccess[1]=!publicaccess[1]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(482)" [statusLabel]="2" [(model)]="property.Description"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[2]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[2], 'bi-square': !publicaccess[2]}" (click)="publicaccess[2]=!publicaccess[2]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(483)" [statusLabel]="2" [(model)]="property.DataType" [items]="datatypes"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[3]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[3], 'bi-square': !publicaccess[3]}" (click)="publicaccess[3]=!publicaccess[3]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(484)" [statusLabel]="2" [(model)]="property.Length"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[4]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[4], 'bi-square': !publicaccess[4]}" (click)="publicaccess[4]=!publicaccess[4]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(485)" [statusLabel]="2" [(model)]="property.Default"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[5]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[5], 'bi-square': !publicaccess[5]}" (click)="publicaccess[5]=!publicaccess[5]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(486)" [statusLabel]="2" [(model)]="property.RegExp"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[6]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[6], 'bi-square': !publicaccess[6]}" (click)="publicaccess[6]=!publicaccess[6]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(487)" [statusLabel]="2" [(model)]="property.Format"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[7]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[7], 'bi-square': !publicaccess[7]}" (click)="publicaccess[7]=!publicaccess[7]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(488)" [statusLabel]="2" [(model)]="property.Style"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[23]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[23], 'bi-square': !publicaccess[23]}" (click)="publicaccess[23]=!publicaccess[23]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(489)" [statusLabel]="2" [(model)]="property.Attr"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[10]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[10], 'bi-square': !publicaccess[10]}" (click)="publicaccess[10]=!publicaccess[10]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(491)" [statusLabel]="2" [(model)]="property.Group" [items]="groups" [optional]="true"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[27]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[27], 'bi-square': !publicaccess[27]}" (click)="publicaccess[27]=!publicaccess[27]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(1184)" [statusLabel]="2" [(model)]="property.TabGroup" [items]="tabgroups" [optional]="true"></swe-element>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="((property.Public>0&&property.Access>1)||permissionService.user.IsSuper)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&((property.Public>0&&property.Access>2)||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Advanced-->
  <div class="col-12 col-md-4 float-start">
    <swe-card [header]="languageService.getItem(492)" [open]="true" [hasRefresh]="false">
      <div *ngIf="permissionService.user.IsSuper||publicaccess[11]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[11], 'bi-square': !publicaccess[11]}" (click)="publicaccess[11]=!publicaccess[11]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(493)" [statusLabel]="2" [(model)]="property.Optional"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[12]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[12], 'bi-square': !publicaccess[12]}" (click)="publicaccess[12]=!publicaccess[12]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(494)" [statusLabel]="2" [(model)]="property.Secret"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[13]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[13], 'bi-square': !publicaccess[13]}" (click)="publicaccess[13]=!publicaccess[13]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(495)" [statusLabel]="2" [(model)]="property.Unique"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[24]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[24], 'bi-square': !publicaccess[24]}" (click)="publicaccess[24]=!publicaccess[24]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(496)" [statusLabel]="2" [(model)]="property.Multiline"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[14]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[14], 'bi-square': !publicaccess[14]}" (click)="publicaccess[14]=!publicaccess[14]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(497)" [statusLabel]="2" [(model)]="property.Track"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[26]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[26], 'bi-square': !publicaccess[26]}" (click)="publicaccess[26]=!publicaccess[26]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(295)" [statusLabel]="2" [(model)]="property.Notification"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper">
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(1293)" [statusLabel]="2" [(model)]="property.Inheritance"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[28]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[28], 'bi-square': !publicaccess[28]}" (click)="publicaccess[28]=!publicaccess[28]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(1246)" [statusLabel]="2" [(model)]="property.Article" [items]="articletypes" [optional]="true" [optionalValue]="0"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(498)" [statusLabel]="2" [(model)]="property.Core" [items]="corevalues"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[15]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[15], 'bi-square': !publicaccess[15]}" (click)="publicaccess[15]=!publicaccess[15]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(499)" [statusLabel]="2" [(model)]="property.VisibleRef"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[16]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[16], 'bi-square': !publicaccess[16]}" (click)="publicaccess[16]=!publicaccess[16]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(500)" [statusLabel]="2" [(model)]="property.VisibleVal"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[17]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[17], 'bi-square': !publicaccess[17]}" (click)="publicaccess[17]=!publicaccess[17]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(501)" [statusLabel]="2" [(model)]="property.ValueRef"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[18]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[18], 'bi-square': !publicaccess[18]}" (click)="publicaccess[18]=!publicaccess[18]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(502)" [statusLabel]="2" [(model)]="property.ValueVal"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[19]">
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[19], 'bi-square': !publicaccess[19]}" (click)="publicaccess[19]=!publicaccess[19]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(503)" [statusLabel]="2" [(model)]="property.Calc" [hint]="calcHint" [validation]="calcValidation" (validationMethod)="validateCalc($event)" *ngIf="!showCalcHint"></swe-element>
      </div>
      <swe-card [header]="languageService.getItem(504)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.user.IsSuper||publicaccess[22]">
        <span swe-rightcommand>
          <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[22], 'bi-square': !publicaccess[22]}" (click)="publicaccess[22]=!publicaccess[22]" *ngIf="permissionService.user.IsSuper"></i>
        </span>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(505)" [statusLabel]="2" [(model)]="property.Identity"></swe-element>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(506)" [statusLabel]="2" [(model)]="property.IdentityStart"></swe-element>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(507)" [statusLabel]="2" [(model)]="property.IdentitySeed"></swe-element>
      </swe-card>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="((property.Public>0&&property.Access>1)||permissionService.user.IsSuper)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&((property.Public>0&&property.Access>2)||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Multiple choice-->
  <div class="col-12 float-start" *ngIf="permissionService.user.IsSuper||publicaccess[21]">
    <swe-card [header]="languageService.getItem(508)" [open]="false" [hasRefresh]="false">
      <span swe-rightcommand>
        <i class="bi bi-swe-fw bi-swe-2x bi-swe-pull-right swe-click" [ngClass]="{'bi-check-square': publicaccess[21], 'bi-square': !publicaccess[21]}" (click)="managepublicaccess(21, $event)" *ngIf="permissionService.user.IsSuper"></i>
      </span>
      <div class="row py-2">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let item of property.Items" [ngClass]="{'list-group-item-danger': item.IsDeleted}">
          <div class="col-6 col-md-3"><div class="list-group-item-heading">{{languageService.getItem(509)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(509)" [statusLabel]="0" [(model)]="item.Name"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(510)}}</div><swe-element [type]="'System.Int32'" [label]="languageService.getItem(510)" [statusLabel]="0" [(model)]="item.Value"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(970)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(970)" [statusLabel]="0" [(model)]="item.ExternalId"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">{{languageService.getItem(511)}}</div><swe-element [type]="'System.Int32'" [label]="languageService.getItem(511)" [statusLabel]="0" [(model)]="item.Sort"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">{{languageService.getItem(512)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(512)" [statusLabel]="0" [(model)]="item.Image"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(513)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(513)" [statusLabel]="0" [(model)]="item.Dependency"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">({{item.Id}})</div><i class="bi bi-swe-fw bi-swe bi-trash-fill swe-click" (click)="remove(item)" *ngIf="!item.IsDeleted"></i><i class="bi bi-swe-fw bi-swe bi-arrow-counterclockwise swe-click" (click)="restore(item)" *ngIf="item.IsDeleted"></i></div>
        </div>
        <div class="swe-click" (click)="add()"><i class="bi bi-plus-circle-fill"></i>&nbsp;{{languageService.getItem(100)}}</div>
      </div>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(514)" [statusLabel]="2" [(model)]="property.Multiple"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(515)" [statusLabel]="2" [(model)]="property.Images"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(516)" [statusLabel]="2" [(model)]="property.ItemsRef"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="((property.Public>0&&property.Access>1)||permissionService.user.IsSuper)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&((property.Public>0&&property.Access>2)||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Help-->
  <div class="col-12 float-start" *ngIf="showformula()">
    <swe-card [header]="languageService.getItem(742)" [open]="false" [hasRefresh]="false" (openChange)="loadPropertyMethods()">
      <div *ngFor="let groupby of propertyMethods | keyvalue : originalOrder" class="mt-2 col-md-6 col-12" [ngClass]="{'border-bottom': !groupby.value.IsOpen}">
        <swe-list [data]="propertyMethods" [loading]="isLoading">
          <div (click)="openToggle(groupby)" class="swe-click">
            <h3 class=" py-1">
              {{groupby.key}}
              <i class="ms-1 bi bi-chevron-down" [ngClass]="{'bi-chevron-right': !groupby.value.IsOpen}"></i>
            </h3>
          </div>
          <div [ngClass]="{'collapse': !groupby.value.IsOpen}">
            <ng-container *ngFor="let method of groupby.value">
              <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click">
                <div class="col-12 col-md-12" *ngIf="method.IsMethod">
                  <span> [{{method.Name}}<span *ngIf="method.Parameters && method.Parameters.length > 0">, {{method.Parameters.join(', ')}}</span>]</span>
                </div>
                <div class="col-md-12 col-12"><span class="fst-italic">{{method.Description}}</span></div>
                <div class="col-12 col-md-12" *ngIf="!method.IsMethod">
                  <span> [{{method.Name}}]</span>
                </div>
              </a>
            </ng-container>
          </div>
        </swe-list>
      </div>
      <swe-element [type]="'System.String'" [label]="languageService.getItem(864)" [statusLabel]="2" [(model)]="formula" (modelChange)="showformulavalues($event)" (validationMethod)="validateTestCalc($event)" [validation]="calcTestValidation"></swe-element>
      <div class="mb-3" *ngFor="let item of formulavalues">
        <swe-element [type]="'System.String'" [label]="item.Key" [statusLabel]="2" [(model)]="item.Value"></swe-element>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="calculate()"><i class="bi bi-calculator"></i>&nbsp;{{languageService.getItem(865)}}</button>
      </div>
      <div class="mb-3 mt-3">
        <div class="alert alert-success" *ngIf="formularesult.length>0">
          <h4>{{languageService.getItem(866)}}</h4>
          <span>{{formularesult}}</span>
        </div>
        <div class="alert alert-danger" *ngIf="formulaerror.length>0">
          <h4>Fel</h4>
          <span>{{formulaerror}}</span>
        </div>
      </div>
      <div class="mb-3 mt-3 d-flex flex-wrap">
        <!--Operators-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(867)}}</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">+</div>
              <div class="col-9 col-md-11">= Plus</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">-</div>
              <div class="col-9 col-md-11">= Minus</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">*</div>
              <div class="col-9 col-md-11">= Times</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">/</div>
              <div class="col-9 col-md-11">= Divide</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">^</div>
              <div class="col-9 col-md-11">= Roof (to the power of, ex. 2^3 = 8)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">?</div>
              <div class="col-9 col-md-11">= If</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">:</div>
              <div class="col-9 col-md-11">= Else</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">></div>
              <div class="col-9 col-md-11">= Greater than</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1"><</div>
              <div class="col-9 col-md-11">= Less than</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">=</div>
              <div class="col-9 col-md-11">= Equal</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">!</div>
              <div class="col-9 col-md-11">= Not equal</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">~</div>
              <div class="col-9 col-md-11">= NullInvert (if value then Null else value)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">#</div>
              <div class="col-9 col-md-11">= DateTime format</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">£</div>
              <div class="col-9 col-md-11">= TimeSpan format</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">$</div>
              <div class="col-9 col-md-11">= Double format</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">%</div>
              <div class="col-9 col-md-11">= Contains</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&#123;</div>
              <div class="col-9 col-md-11">= Starts with</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&#125;</div>
              <div class="col-9 col-md-11">= Ends with</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">|</div>
              <div class="col-9 col-md-11">= Or</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&</div>
              <div class="col-9 col-md-11">= And</div>
            </div>
          </div>
        </div>
        <!--Operands-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(868)}}</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">´</div>
              <div class="col-9 col-md-11">= Empty string</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">¤</div>
              <div class="col-9 col-md-11"> = Null</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(869)}}</h4>
            <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(870)" [statusLabel]="2" [(model)]="datetime" (modelChange)="manageDateTime($event)"></swe-element>
            <div class="mb-3">
              <div class="alert alert-success" *ngIf="datetimeresult>0">
                <span>{{datetimeresult}}</span>
              </div>
            </div>
            <swe-element [bottomMargin]="0" [type]="'System.List'" [label]="languageService.getItem(621)" [statusLabel]="2" [(model)]="timeinterval" (modelChange)="manageTimeInterval($event)" [items]="timeintervals" [optional]="true"></swe-element>
            <swe-element [type]="'System.List'" [label]="languageService.getItem(621)" [statusLabel]="0" [(model)]="timeunit" (modelChange)="manageTimeInterval($event)" [items]="timeunits"></swe-element>
            <div class="mb-3">
              <div class="alert alert-success" *ngIf="timeunitresult>0">
                <span>{{timeunitresult}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swe-card>
  </div>
</div>
