<nav class="navbar navbar-dark bg-dark swe-topmenu">
  <div class="container-fluid">
    <button type="button" class="navbar-toggler" [ngClass]="{'d-none d-md-block': menuService.ShowSearch}" (click)="menuService.toggleMenu()" *ngIf="authenticationService.isAuth" accesskey="3" [disabled]="menuService.ShowMenu ? true : null" #sweFocusElement>
      <span><i class="bi bi-list bi-swe-topmenu"></i></span>
      <span class="sr-only">{{languageService.getItem(1)}}</span>
    </button>
    <div class="flex-grow-1 mx-sm-3 mx-md-5 col">
      <div class="mx-auto dropdown col-md-5 col-lg-3 col-6">
        <input [attr.aria-label]="languageService.getItem(13)" accesskey="0" type="text" class="form-control" placeholder="{{languageService.getItem(13)}}" [(ngModel)]="searchstring" (ngModelChange)="search(0)" (keyup)="keyupSearch($event)" (keydown)="keydownSearch($event)" (blur)="lostfocusSearch()" *ngIf="authenticationService.isAuth" #swePowerSearchElement/>
        <div class="dropdown-menu dropdown-menu-right py-0" [ngClass]="{'show': isSearching}">
          <div class="list-group" *ngFor="let group of searchresult;let i = index">
            <h6 class="list-group-item-secondary dropdown-header">{{group.header}}</h6>
            <ng-container *ngFor="let item of group.rows;let j = index">
              <a class="list-group-item swe-click" style="white-space:nowrap;" [ngClass]="{'active': current == getIndex(i,j)}" (click)="get(item)">
                {{item.description}}
              </a>
            </ng-container>
          </div>
          <div class="list-group">
            <a class="list-group-item list-group-item-info text-center swe-click" [ngClass]="{'active': current == total}" *ngIf="more" (click)="search(1)">{{languageService.getItem(57)}}</a>
            <a class="list-group-item list-group-item-danger text-center swe-click" [ngClass]="{'active': current == total+1}" *ngIf="all" (click)="search(2)">{{languageService.getItem(340)}}</a>
            <a class="list-group-item list-group-item-info swe-click" *ngIf="total==0">{{languageService.getItem(58)}}</a>
          </div>
        </div>
      </div>
    </div>
    <!--ServerSentEvents-->
    <div class="dropdown me-1 me-md-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="sseList.length>0">
      <i class="bi bi-swe-topmenu bi-swe-inverse swe-click swe-print-remove" [ngClass]="{'bi-envelope-fill': sseNew, 'bi-envelope': !sseNew}" (click)="toggleSSE()" data-bs-toggle="dropdown">
        <span class="position-absolute top-0 start-100 translate-middle badge small rounded-pill bg-danger" style="font-size:0.4em;" *ngIf="sseUnseen>0">{{sseUnseen}}</span>
      </i>
      <div class="dropdown-menu dropdown-menu-end px-2 mt-2 swe-alert-history" [ngClass]="{'show': sseMenu}">
        <div class="list-group-item list-group-item-secondary swe-alert-text swe-click" *ngFor="let sse of sseList" (click)="manageSSE(sse,$event)">
          <span (click)="removeSSE(sse,$event)"><i class="bi bi-trash-fill float-end swe-click" [attr.title]="languageService.getItem(17)"></i></span>
          <span [ngClass]="{'fw-bold': !sse['clicked']}">{{sse['time']}}&nbsp;{{sse.Value}}</span>
        </div>
        <div class="list-group-item" *ngIf="sseList.length>1">
          <i class="bi bi-trash-fill float-end swe-click" [attr.title]="languageService.getItem(1187)" (click)="removeSSEAll($event)"></i>
          &nbsp;
        </div>
      </div>
    </div>
    <!--AlertHistory-->
    <div class="dropdown me-1 me-md-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="alertService.History.length>0&&authenticationService.isAuth">
      <i class="bi bi-swe-topmenu bi-swe-inverse swe-click swe-print-remove" [ngClass]="{'bi-bell-fill': historynew, 'bi-bell': !historynew}" (click)="toggleHistory()" data-bs-toggle="dropdown"></i>
      <div class="dropdown-menu dropdown-menu-end px-2 mt-2 swe-alert-history" [ngClass]="{'show': historymenu}">
        <div class="list-group-item list-group-item-{{alert.type}} swe-alert-text" *ngFor="let alert of alertService.History">
          <i class="bi bi-trash-fill float-end swe-click" [attr.title]="languageService.getItem(17)" (click)="manageHistory(alert,$event)"></i>
          {{alert['time']}}&nbsp;{{alert.message}}
        </div>
        <div class="list-group-item" *ngIf="alertService.History.length>1">
          <i class="bi bi-trash-fill float-end swe-click" [attr.title]="languageService.getItem(1187)" (click)="manageHistoryAll($event)"></i>
          &nbsp;
        </div>
      </div>
    </div>
    <div class="dropdown me-1 me-md-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="authenticationService.isAuth&&permissionService.isimpersonate()!=0">
      <i class="bi bi-incognito bi-swe-topmenu bi-swe-inverse swe-click swe-print-remove" (click)="toggle()" data-bs-toggle="dropdown"></i>
      <div class="dropdown-menu dropdown-menu-end p-2 mt-2 text-center" [ngClass]="{'show': impersonatemenu}">
        <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="3" [(model)]="pincode" (keyup)="keyup($event)" [setFocus]="pinfocus" [enterKeyHint]="'enter'"></swe-element>
      </div>
    </div>
    <button type="button" class="navbar-toggler me-1 me-md-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu}" (click)="menuService.toggleSearch()" *ngIf="authenticationService.isAuth&&settingService.visible>0&&!menuService.ShowSearch" accesskey="4" #sweFocusElement2>
      <span><i class="bi bi-search bi-swe-topmenu"></i></span>
      <span class="sr-only">Filtrering</span>
    </button>
    <div class="dropdown" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="authenticationService.isAuth">
      <img (click)="profilemenu=!profilemenu;impersonatemenu=false;" class="swe-circle-image swe-click swe-print-remove" alt="{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="permissionService.user.Image" imageWidth="32" imageHeight="32" *ngIf="permissionService.user&&permissionService.user.Image&&permissionService.user.Image.length>0" data-bs-toggle="dropdown" />
      <i class="bi bi-person-fill bi-swe-topmenu bi-swe-inverse swe-click swe-print-remove" (click)="profilemenu=!profilemenu;impersonatemenu=false;" *ngIf="!permissionService.user||!permissionService.user.Image||permissionService.user.Image.length==0" data-bs-toggle="dropdown"></i>
      <div class="dropdown-menu dropdown-menu-end p-2 mt-2" style="min-width: 200px; margin-right: -10px;" [ngClass]="{'show': profilemenu}">
        <div class="text-center">
          <i class="bi bi-person bi-swe-3x" *ngIf="!permissionService.user||!permissionService.user.Image||permissionService.user.Image.length==0"></i>
          <img alt="{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="permissionService.user.Image" *ngIf="permissionService.user&&permissionService.user.Image&&permissionService.user.Image.length>0" />
        </div>
        <div class="py-2" style="white-space:nowrap;" [ngClass]="{'text-center': !permissionService.permissions||!permissionService.permissions.PushNotifications}">
          <button class="btn me-1" [disabled]="pushloading" [ngClass]="{'btn-primary': pushaccepted, 'btn-outline-primary': !pushaccepted}" (click)="togglePushSupport($event)" *ngIf="permissionService.permissions&&permissionService.permissions.PushNotifications" [attr.title]="pushaccepted?languageService.getItem(1231):languageService.getItem(1230)"><i class="bi" [ngClass]="{'bi-bell-fill': pushaccepted, 'bi-bell-slash-fill': !pushaccepted}"></i></button>
          <span *ngIf="permissionService.user">{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}</span>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="goto('/users/' + permissionService.user.Id)"><i class="bi bi-person-fill"></i>&nbsp;{{languageService.getItem(103)}}</button>
          <button class="btn btn-primary" (click)="goto('/help')" *ngIf="permissionService.permissions&&permissionService.permissions.Help>0"><i class="bi bi-question-lg"></i>&nbsp;{{languageService.getItem(742)}}</button>
          <button class="btn btn-primary" (click)="goto('/login?logout=1')"><i class="bi bi-door-closed-fill"></i>&nbsp;{{languageService.getItem(5)}}</button>
        </div>
      </div>
    </div>
  </div>
</nav>


