import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';
import { SettingService } from '../../_services/setting.service';


@Component({
  selector: 'swe-objectdata',
  templateUrl: './objectdata.component.html'
})
export class ObjectDataComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() category: number;
  @Input() header: string = '';
  @Input() open: boolean;

  private _subscriptions: Subscription[] = [];
  private _categories: any[] = [];
  private _object: any = {};
  private _loading: boolean;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private propertyService: PropertyService,
    private listService: ListService,
    private settingService: SettingService,
    private router: Router
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        if (this._object.Access > 1) {
          this.save();
        }
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges() {
    if (this.open) {
      this._categories = [];
      let list = this.listService.formatArray(this.generalService.propertycategories, ['Object'], 'Extra');
      list.forEach((item) => {
        this._categories.push({ Id: item.Key, Name: item.Value });
      });

      this.load();
    }
    
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get object() {
    return this._object;
  }
  public get categories() {
    return this._categories;
  }



  /*Methods*/
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/objects/' + this.category + '/' + this.id , 'GET')
      .subscribe(res => {
        if (res) {

          this._object = res;
          this._loading = false;
        }
      });
  }
  public create(e) {
    e.stopPropagation();

    let url = '/objects/' + this.category + '/0';

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public save() {
    if (!this.propertyService.validate(this._object.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/objects/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    let dto = {
      Category: this.category,
      Profile: this._object.Profile
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
        }
        else {
          this.load();
        }

      });
  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/objects/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.router.navigateByUrl('/objects/' + this.category);
      });
  }


  //Functions
  
}
