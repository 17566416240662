<swe-card header="{{languageService.getItem(1077)}}" [(open)]="open" (openChange)="search()" (refreshChange)="search()" toolbarClass="mt-1" *ngIf="data&&data.Id>0" [rulelist]="new ? ['SweUserRequests'] : ['SweBookingRequests']" icon="bi-send">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <div class="bi-swe-pull-right mb-2 d-none d-sm-flex" *ngIf="new&&requestgroups.length>0" swe-rightcommand>
    <swe-dropdown icon="bi-plus-lg" [showLabel]="true" [(isOpen)]="addMenu" [buttonclass]="'btn-success'" [label]="languageService.getItem(100)">
      <ul class="dropdown-menu" [ngClass]="{'show': addMenu }">
        <li class="dropdown-item swe-click" (click)="goto(0, 0, $event)">
          <i class="bi bi-swe-fw bi-person-fill" [attr.title]="languageService.getItem(1110)"></i>
          {{languageService.getItem(1110)}}
        </li>
        <li class="dropdown-item swe-click my-1" (click)="goto(0, group.Id, $event)" *ngFor="let group of requestgroups">
          <i class="bi bi-swe-fw bi-people-fill" [attr.title]="group.Name"></i>
          {{group.Name}}
        </li>
      </ul>
    </swe-dropdown>
  </div>
  <button swe-rightcommand class="btn btn-success bi-swe-pull-right" (click)="goto(0, 0, $event)" *ngIf="new&&requestgroups.length==0"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
  <swe-datepager [type]="'booking'" (dateChange)="search(false, $event)" [disabled]="loading" [hideTime]="true" *ngIf="userid>0"></swe-datepager>
  <div class="btn-group ms-3 align-top">
    <!--Email Notification-->
    <button [attr.aria-label]="languageService.getItem(1222)" [attr.title]="languageService.getItem(1222)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationemail, 'bi-check-square': checknotificationemail}"></i>
      <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
    </button>
    <!--SMS Notification-->
    <button [attr.aria-label]="languageService.getItem(1221)" [attr.title]="languageService.getItem(1221)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationsms, 'bi-check-square': checknotificationsms}"></i>
      <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
    </button>
    <span class="small ms-1" *ngIf="bookingid==0">{{languageService.getItem(1149)}}</span>
  </div>
  <div class="d-inline-block ms-3 align-top" *ngIf="!new&&requestgroups.length>0">
    <swe-dropdown [showLabel]="true" [(isOpen)]="groupMenu" [label]="requestgroupid > 0 ? requestgroupname : '[' + languageService.getItem(1449) + ']'" [icon]="'bi-funnel-fill'">
      <ul class="dropdown-menu" [ngClass]="{'show': groupMenu }">
        <li class="dropdown-item swe-click" (click)="changegroup(0, $event)">
          <div class="row">
            <div class="col-10">
              <i class="bi bi-swe-fw bi-funnel-fill"></i>
              <span class="ms-1">[{{languageService.getItem(1449)}}]</span>
            </div>
            <div class="col-2">
              &nbsp;
            </div>
          </div>
        </li>
        <li class="dropdown-item swe-click" (click)="changegroup(group.Id, $event)" *ngFor="let group of requestgroups">
          <div class="row">
            <div class="col-10" [attr.title]="languageService.getItem(1454)+' '+group.Name">
              <i class="bi bi-swe-fw bi-funnel-fill" [attr.title]="group.Name"></i>
              <span class="ms-1">{{group.Name}}</span>
            </div>
            <div class="col-2">
              <i class="bi bi-swe-fw" [ngClass]="{'bi-check-square': group.Subscribe, 'bi-square': !group.Subscribe}" (click)="toggleSubscribe(group, $event)" [attr.title]="languageService.getItem(1450)"></i>
            </div>
          </div>
        </li>
      </ul>
    </swe-dropdown>
  </div>
  <swe-list [data]="requests" [loading]="loading">
    <div class="list-group-item list-group-item-action d-flex flex-wrap" [ngClass]="{'list-group-item-danger': request.Responses.length==0,'list-group-item-info': request.Personal,'list-group-item-secondary': request.GroupId>0}" *ngFor="let request of requests" (click)="request.Access>0&&goto(request.Id, request.GroupId, $event)">
      <div class="col-12 col-md-2 swe-click">{{dateTimeService.format(request.DateTime)}}</div>
      <div class="col-12 col-md-2 swe-click">{{request.Name}}</div>
      <div class="col-12 col-md-3 swe-click" [attr.title]="request.Question">
        <i class="bi bi-swe-fw bi-person-fill me-1" *ngIf="request.Personal"></i>
        <i class="bi bi-swe-fw bi-people-fill me-1" *ngIf="request.GroupId>0"></i>
        <span class="font-weight-bold" *ngIf="request.Header.length>0">{{request.Header}} </span>
        <span>{{request.Question.substring(0,40)}}</span>
        <span *ngIf="request.Question.length>40">...</span>
      </div>
      <div class="col-12 col-md-4 d-flex flex-wrap swe-click">
        <ng-container *ngFor="let response of request.Responses | slice:0:3;">
          <div class="col-12 col-md-2 overflow-hidden text-nowrap">
            {{response.Sender.length > 10 ? response.Sender.substring(0,7) : response.Sender}}<span *ngIf="response.Sender.length>10">...</span>
          </div>
          <div class="col-12 col-md-6 overflow-hidden  ps-2 text-nowrap">{{response.OptionText}}<span class="ms-1" *ngIf="response.Response&&response.Response.length>0">({{response.Response}})</span></div>
          <div class="col-12 col-md-4 overflow-hidden ps-2 text-nowrap">{{dateTimeService.format(response.DateTime)}}</div>
        </ng-container>
      </div>
      <div class="col-12 col-md-1" [ngStyle]="{'cursor': request.Access>0 ? 'pointer':'not-allowed'}">
        <button class="btn btn-secondary me-1 mb-1" *ngFor="let option of request.Options" (click)="answer(request.Id, option.Key, $event)" [disabled]="request.Access==0">{{option.Value}}</button>
      </div>
    </div>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true, $event)">{{languageService.getItem(57)}}</button>
  </swe-list>
</swe-card>
