<swe-card [header]="languageService.getItem(376)" [hasRefresh]="false" [hasCollapse]="true">
  <swe-element [type]="'System.String'" [label]="languageService.getItem(379)" [statusLabel]="1" [(model)]="message.Type" [access]="1"></swe-element>
  <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(377)" [statusLabel]="1" [(model)]="message.CreatedTime" [format]="'yyyy-MM-dd HH:mm:ss'" [access]="1"></swe-element>
  <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(378)" [statusLabel]="1" [(model)]="message.SendTime" [format]="'yyyy-MM-dd HH:mm:ss'" [access]="1"></swe-element>
  <swe-element [type]="'System.String'" [label]="languageService.getItem(322)" [statusLabel]="1" [(model)]="message.FullAddress" [access]="1"></swe-element>
  <swe-element [type]="'System.String'" [label]="languageService.getItem(327)" [statusLabel]="1" [(model)]="message.Subject" [access]="1" *ngIf="message.Subject&&message.Subject.length>0"></swe-element>
  <swe-element [type]="'System.TextArea'" [label]="languageService.getItem(328)" [statusLabel]="1" [(model)]="message.Body" [access]="1" [hideCounter]="true"></swe-element>
  <div class="mb-3 mt-3">
    <div *ngFor="let attachment of message.AttachmentList">
      <a class="text-primary swe-click" (click)="download(attachment)"><i class="bi bi-swe-fw bi-swe bi-paperclip"></i>&nbsp;{{attachment.Original}}</a>
    </div>
  </div>
  <div class="mb-3 mt-3">
    <swe-card [header]="languageService.getItem(4)" [color]="'swe'" [hasRefresh]="false" *ngIf="message.Bookings&&message.Bookings.length>0">
      <div class="row" *ngFor="let booking of message.Bookings" (click)="gotoShift(booking.Id, $event)">
        <div class="col-12 col-md-4 swe-click">{{booking.datetimeheader}}</div>
        <div class="col-12 col-md-4 swe-click">{{booking.Header}}</div>
        <div class="col-12 col-md-4 swe-click">{{booking.Level}}</div>
      </div>
    </swe-card>
  </div>
  <div class="d-grid gap-2" *ngIf="message.IsEmail&&permissionService.permissions.Email>0">
    <button class="btn btn-primary" (click)="resend()"><i class="bi" [ngClass]="{'bi-envelope-fill': message.IsEmail, 'bi-phone-fill': message.IsSms}"></i>&nbsp;{{languageService.getItem(768)}}&nbsp;({{languageService.getItem(325)}})</button>
  </div>
</swe-card>
