import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-usermarkeddates',
  templateUrl: './usermarkeddates.component.html'
})
export class UserMarkedDatesComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _usermarkeddates = [];
  private _more: boolean = false;
  private _all: boolean = false;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  //private _toggleaccess: boolean = false;
  private _listutility: ListUtility = new ListUtility();

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private viewCacheService: ViewCacheService,
    private router: Router) {

    settingService.initView(2 | 8 | 16 | 512, ['User', 'Level', 'UserMarkedDate']);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {

    this._listutility.toggleaccess = (this.permissionService.permissions.MultiMarkedDate > 0);

    this.search();
  }

  /*Properties*/
  public get usermarkeddates() {
    return this._usermarkeddates;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get more() : boolean {
    return this._more;
  }
  public get all(): boolean {
    return this._all;
  }
  public get hits(): number {
    return this._hits;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  //public get toggleaccess() {
  //  return this._toggleaccess;
  //}
  public get listutility() {
    return this._listutility;
  }



  /*Methods*/
  public search(getmore: boolean = false, all: boolean = false) {

    this._more = false;
    this._all = false;

    let top = 25;
    if (typeof all != "undefined" && all) {
      top = 0;
    }

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._usermarkeddates = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      Start: this.settingService.start('usermarkeddate'),
      End: this.settingService.end('usermarkeddate'),
      Available: this.settingService.user.availability,
      AvailableTypes: this.settingService.user.availabilitylist.join(),
      Employment: this.settingService.user.employment,
      EmploymentCategories: this.settingService.user.employmentcategories,
      FrameworkContracts: this.settingService.user.frameworkcontracts,
      Contract: this.settingService.user.contract,
      Contracts: this.settingService.user.contracts,
      Booking: this.settingService.user.bookingtype,
      LevelGroups: this.settingService.user.levelgroups.join(),
      LevelList: this.settingService.levelList(1),
      OnlyParentLevelList: this.settingService.levelList(1, true),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/usermarkeddates/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.UserMarkedDates.forEach(usermarkeddate => {

            let start = new Date(usermarkeddate.Start);
            let end = new Date(usermarkeddate.End);

            usermarkeddate.dateheader = this.dateTimeService.header(start, end, false);

            this._usermarkeddates.push(usermarkeddate);
          });

          this._listutility.rows = this._usermarkeddates;
          this._hits = this._usermarkeddates.length;
          this._more = res.More;
          this._all = (res.More && this._usermarkeddates.length > 0);

          this._loading = false;
        }
      });
  }
  public open(id: number, e) {
    let url = '/usermarkeddates/' + id;
    if (id == 0) {
      url += '/redirect/1';
    }
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public action(option) {

    let usermarkeddates = [];
    this._usermarkeddates.forEach((umd) => {
      if (umd.checked && umd.Access > 1) {
        usermarkeddates.push(umd.Id);
      }
    });

    if (usermarkeddates.length > 0) {

      this.viewCacheService.add('multi_usermarkeddates', usermarkeddates);

      this.router.navigate(['/usermarkeddates/action/' + option + '/']);
    }
  }

  public handleSearchEvent($event) {
    this.search($event.getMore, $event.getAll);
  }

  //Check Users
  //public toggle(row, e) {

  //  e.stopPropagation();

  //  if (!this._toggleaccess || row.Access < 2) {
  //    return;
  //  }

  //  row.checked = !row.checked;

  //  this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._usermarkeddates.forEach((umd) => {
  //    if (umd.Access > 1) {
  //      umd.checked = option;
  //    }
  //  });

  //  this._checkcounter = option ? this._usermarkeddates.length : 0;
  //  this._ischecked = option;
  //}
}
