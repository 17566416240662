import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { ListUtility } from "../_utilities/list.utility";
import { LanguageService } from "../_services/language.service";
import { SettingService } from "../_services/setting.service";

@Component({
  selector: 'swe-fixedheader',
  templateUrl: 'fixedheader.component.html',
})
export class FixedHeaderComponent implements AfterViewInit {

  @Input() datePagerOpt: DatePagerOptions;
  @Input() listutility: ListUtility;
  @Input() all: boolean;
  @Input() more: boolean;
  @Input() access: Number;
  @Input() listoptions: TemplateRef<any>;
  @Input() extra: TemplateRef<any>;
  @Input() after: TemplateRef<any>;
  @Input() resetsticky: boolean;
  @Input() isOpen: boolean = true;
  @Input() actionsOnSecondRow: boolean = false;
  @Input() extraPermissions: boolean = true;
  @Output() searchEvent = new EventEmitter<any>();
  @Input() showReturnToTop = true;

  private _canReturnToTop: boolean;
  private _loading: boolean;
  private _scrolling: boolean;
  private _openFlag: boolean;
  private _hasTwoRows: boolean = false;
  private _previousScroll: number = 0;
  constructor(public languageService: LanguageService, private settingService: SettingService, private _cd: ChangeDetectorRef) {

  }

  public get hasTwoRows() {
    return this._hasTwoRows;
  }
  public get canReturnToTop() {
    return this._canReturnToTop;
  }
  public get scrolling() {
    return this._scrolling;
  }

  ngAfterViewInit() {
    if (this.settingService.isDevice(['sm', 'md', 'xs'])) {
      if (this.all || this.more || (this.extra&&this.extraPermissions)) {
        this._hasTwoRows = true;
      }
    } else if (this.after) {
      this._hasTwoRows = true;
    }
    this._cd.detectChanges();

  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (document.documentElement.scrollTop > 20) {
      this._scrolling = true;
      if (this._openFlag) {
        this.isOpen = false;
        this._openFlag = false;
      }
    }
    else if (document.documentElement.scrollTop < 10) {
      this._scrolling = false;
      if (this._previousScroll >= 10) {
        this.isOpen = true;
      }
      this._openFlag = true;
    }

    if (document.documentElement.scrollTop > 50 && this._previousScroll > document.documentElement.scrollTop) {
      this._canReturnToTop = true;
    } else {
      this._canReturnToTop = false;
    }
    this._previousScroll = document.documentElement.scrollTop;
  }

  scrollToTop() {
    if (!this._canReturnToTop || !this.showReturnToTop) {
      return;
    }
    window.scrollTo(0, 0);
   }

  public get loading() {
    return this._loading;
  }
  public toggleAfter() {
    if (!this._hasTwoRows) {
      return;
    }
    this.isOpen = !this.isOpen;
  }
  public search(getMore: boolean = false, getAll: boolean = false) {
    this.searchEvent.emit({ getMore: getMore, getAll: getAll });
  }
}


export interface DatePagerOptions {
  hideTime: Boolean
  type: String
}
