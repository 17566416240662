import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html'
})
export class LevelsComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _levels = [];
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  //private _toggleaccess: boolean = false;
  private _listutility: ListUtility = new ListUtility();

  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private viewCacheService: ViewCacheService,
    private router: Router
  ) {

    settingService.initView(4 | 16 | 512, ['Level']);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {

    this._listutility.toggleaccess = ((this.permissionService.permissions.Email > 0) || (this.permissionService.permissions.MultiLevel > 0));

    this.search();
  }

  /*Properties*/
  public get levels() {
    return this._levels;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get more() : boolean {
    return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  //public get toggleaccess() {
  //  return this._toggleaccess;
  //}
  public get listutility() {
    return this._listutility;
  }


  public handleSearchEvent($event) {
    this.search($event.getMore);
  }

  /*Methods*/
  public search(getmore: boolean = false) {

    this._more = false;

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._levels = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      LevelGroups: this.settingService.level.levelgroups.join(),
      FrameworkContracts: this.settingService.level.frameworkcontracts,
      Contract: this.settingService.level.contract,
      Contracts: this.settingService.level.contracts,
      LevelList: this.settingService.levelList(3),
      OnlyParentLevelList: this.settingService.levelList(3, true),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Levels.forEach(level => {
            this._levels.push(level);
          });

          this._listutility.rows = this._levels;
          this._hits = this._levels.length;
          this._more = res.More;

          this._loading = false;
        }
      });
  }
  public open(id: number, e) {
    let url = '/levels/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public send(type: number) {

    let levels = [];
    this._levels.forEach((level) => {
      if (level.checked) {
        levels.push(level);
      }
    });
    this.viewCacheService.add('message_levels', levels);

    let url = '';
    if (type == 1) {
      url = '/messages/send/email';
    }
    
    this.router.navigate([url]);
  }
  public action(option) {

    let levels = [];
    this._levels.forEach((level) => {
      if (level.checked && level.Access > 1) {
        levels.push(level.Id);
      }
    });

    if (levels.length > 0) {

      this.viewCacheService.add('multi_levels', levels);

      this.router.navigate(['/levels/action/' + option + '/']);
    }
  }


  //Check Users
  //public toggle(row, e) {

  //  e.stopPropagation();

  //  if (!this._toggleaccess || row.Access < 2) {
  //    return;
  //  }

  //  row.checked = !row.checked;

  //  this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._levels.forEach((level) => {
  //    if (level.Access > 1) {
  //      level.checked = option;
  //    }
  //  });

  //  this._checkcounter = option ? this._levels.length : 0;
  //  this._ischecked = option;
  //}


}
