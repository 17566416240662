import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { PermissionService } from '../_services/permission.service';
import { AlertService } from '../_services/alert.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';



@Component({
  selector: 'swe-generalmessages',
  templateUrl: './generalmessages.component.html'
})
export class GeneralMessagesComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() data: any;
  @Input() type: string = "";

  private _messages = [];
  private _more: boolean = false;
  private _hits: number = 0;
  private _multiple: number = 1;
  private _loading: boolean = false;
  private _messagestart: Date;
  private _messageend: Date = new Date(2100, 1, 1);
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  private _listutility: ListUtility = new ListUtility();
  

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private alertService: AlertService,
    private viewCacheService: ViewCacheService,
    private router: Router
  ) {
    
  }

  ngOnInit() {

    this._listutility.hasdataaccess = false;
  }
  
  ngOnChanges() {
    if (this.data && this.data.Created) {
      this._messagestart = new Date(this.data.Created);
      if (this.open) {
        this.search(false);
      }
    }
  }
  

  /*Properties*/
  public get messages() {
    return this._messages;
  }
  public get more(): boolean {
    return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get listutility() {
    return this._listutility;
  }




  /*Methods*/
  public search(getmore: boolean = false) {

    this._more = false;

    let top = 25;
    
    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._messages = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      //Logs
      Start: this._messagestart,
      End: this._messageend
    };




    let url = '/api/v1/messages/search';
    let verb = 'POST';
    let dto = {};
    if (this.type == "user") {
      filter["UserId"] = this.id;
      dto = filter;
    }
    else if (this.type == "booking") {
      url = '/api/v1/bookings/' + this.id + '/messages';
      verb = 'GET';
    }
    else if (this.type == "level") {
      filter["LevelId"] = this.id;
      dto = filter;
    }
    else {
      return;
    }

    this._loading = true;

    this.dataService.tokenRequest(url, verb, dto)
      .subscribe(res => {
        if (res) {

          if (res.Messages) {
            res.Messages.forEach(message => {
              this._messages.push(message);
            });
          }
          else {
            res.forEach(message => {
              this._messages.push(message);
            });
          }

          this._listutility.rows = this._messages;
          this._hits = this._messages.length;
          this._more = res.More;
          
          this._loading = false;
        }
      });
  }
  public goto(id: number, categoryId: number) {

    let category = 'outgoing';
    if (categoryId != null && typeof categoryId != "undefined") {
      if (categoryId == 2) {
        category = 'incoming';
      }
      else if (categoryId == 3) {
        category = 'reply';
      }
    }

    this.router.navigate(['/messages', category, id]);
  }
  public resend() {

    if (!this.permissionService.permissions.ReplySms) { return; }

    this._actionmenu = false;
    this._listutility.ischecked = false;

    let resendlist = [];
    this._messages.forEach((message) => {
      if (message.checked) {
        resendlist.push(message.Id);
      }
    });

    if (resendlist.length > 0) {
      this.dataService.tokenRequest('/api/v1/messages/0/resend', 'POST', resendlist, 'text')
        .subscribe((res) => {

          this.alertService.Add({ type: 'success', message: res });

          this.search();

        });
    }
  }
  



  //Check Messages
  //public toggle(row, e) {

  //  e.stopPropagation();

  //  row.checked = !row.checked;

  //  this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {

  //  this._messages.forEach((message) => {
  //    message.checked = option;
  //  });

  //  this._checkcounter = option ? this._messages.length : 0;
  //  this._ischecked = option;
  //}


  //Functions
  
}
