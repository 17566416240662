import { Component, Input, EventEmitter, Output, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { PropertyService } from '../_services/property.service';
import { DateTimeService } from '../_services/datetime.service';
import { LanguageService } from '../_services/language.service';
import { isNumber } from 'util';



@Component({
  selector: 'swe-datepicker',
  templateUrl: './datepicker.component.html',
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() changed: boolean;
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() alwaysshowcontents: boolean;
  @ViewChild('txtDate') input: ElementRef;

  private _open: boolean = false;
  private _openMonth: boolean = false;
  private _openYear: boolean = false;
  private _years: Array<number> = [];
  private _months: Array<any> = [];
  private _days: any = [,];

  private _edit: boolean = false;

  private _currentYear: number;
  private _currentMonth: number;
  private _currentDay: number;

  private _formatDate: string;
  private _manualDate: string;


  constructor(private propertyService: PropertyService, private dateTimeService: DateTimeService, private languageService: LanguageService) {

  }


  ngOnInit() {

    

    //Years
    let startyear = 1900;
    let endyear = new Date().getFullYear() + 10;
    for (var i = startyear; i < endyear; i++) { this._years.push(i); }

    //Months
    this._months = [];
    for (var i = 0; i < 12; i++) { this._months.push({ Id: i, Name: this.dateTimeService.monthnamebynumber(i, 3) }); }


    this.init();
  }

  ngOnChanges() {
    this.init();
  }




  /*Properties*/
  public get open():boolean {
    return this._open;
  }
  public get formatDate(): string {
    return this._formatDate;
  }
  public set formatDate(val) {
    this._manualDate = val;
  }
  public get openYear(): boolean {
    return this._openYear;
  }
  public get years(): Array<number> {
    return this._years;
  }
  public get currentYear(): number {
    return this._currentYear;
  }
  public set currentYear(val) {
    this._currentYear = val;
    this.write();
  }
  public get openMonth(): boolean {
    return this._openMonth;
  }
  public get months(): Array<any> {
    return this._months;
  }
  public get currentMonth(): number {
    return this._currentMonth;
  }
  public set currentMonth(val) {
    this._currentMonth = val;
    this.write();
  }
  public get currentMonthName(): string {
    return this.dateTimeService.monthnamebynumber(this._currentMonth);
  }
  public get days(): any {
    return this._days;
  }
  public get edit(): boolean {
    return this._edit;
  }



  //Methods
  public weeknumber(date) {
    return this.dateTimeService.weeknumber(date);
  }
  public toggle() {
    this._open = !this._open;
    if (this._open) {
      this.setFocus();
    }
  }
  public close() {
    this._open = false;
  }
  public show() {
    //Wait for button click
    setTimeout(() => {
      this._edit = true;
      this.setFocus();
    }, 500);
  }
  public toggleMonth() {
    this._openMonth = !this._openMonth;
  }
  public toggleYear() {
    this._openYear = !this._openYear;

    if (this._openYear) {
      if (this._currentYear < 1900 || this._currentYear > (new Date().getFullYear() + 10)) {
        this._currentYear = new Date().getFullYear() + 1;
        this._currentMonth = 0;
        this._currentDay = 1;

        this.write();
      }
    }
  }
  public prev() {
    this._currentMonth--;
    if (this._currentMonth < 0) {
      this._currentMonth = 11;
      this._currentYear--;
    }
    this.write();
  }
  public next() {
    this._currentMonth++;
    if (this._currentMonth > 11) {
      this._currentMonth = 0;
      this._currentYear++;
    }
    this.write();
  }
  public select(date: Date) {
    this._openMonth = false;
    this._openYear = false;
    this._open = false;

    this._currentYear = date.getFullYear();
    this._currentMonth = date.getMonth();
    this._currentDay = date.getDate();

    this.write();

    this._edit = false;
    this.modelChange.emit(this.dateTimeService.formatWithTimeZone(this.model));
  }
  public keydown(e: KeyboardEvent) {

    let current = new Date(this.model);

    let newDate = false;
    if (e.keyCode == 40) {
      //Arrow key down
      let date = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 7, current.getHours(), current.getMinutes(), current.getSeconds());
      this.model = date;

      newDate = true;
    }
    else if (e.keyCode == 38) {
      //Arrow key up
      let date = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 7, current.getHours(), current.getMinutes(), current.getSeconds());
      this.model = date;

      newDate = true;
    }
    else if (e.keyCode == 37) {
      //Arrow key left
      let date = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, current.getHours(), current.getMinutes(), current.getSeconds());
      this.model = date;

      newDate = true;
    }
    else if (e.keyCode == 39) {
      //Arrow key right
      let date = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1, current.getHours(), current.getMinutes(), current.getSeconds());
      this.model = date;

      newDate = true;
    }
    else if (e.keyCode == 9) {
      //Tab
      if (!e.shiftKey) {
        this.blur();
        return false;
      }
    }
    else if (e.keyCode == 13) {
      //Enter
      if (this._open) {
        this.blur();
        return false;
      }
      else {
        this._open = true;
        return false;
      }
    }
    else if (e.keyCode == 32) {
      //Space
      if (this._open) {
        this.blur();
        return false;
      }
    }

    

    if (newDate) {
      e.stopPropagation();
      this._currentYear = this.model.getFullYear();
      this._currentMonth = this.model.getMonth();
      this._currentDay = this.model.getDate();
    
      this.write();
      this.setFocus();

      //this._edit = false; (Removed 230830 - needs to be in edit mode to make keyarrows change dates more than one time)
      this.modelChange.emit(this.dateTimeService.formatWithTimeZone(this.model));
      

      return false;
    }
  }
  public blur() {

    let current = this.specialDateTimeFormat();
    
    if (this.dateTimeService.isValid(current) && current.getFullYear() > 1900 && current.getFullYear() < 2099) {
      this._manualDate = '';
    }
    else {
      current = new Date(this.model);
    }

    this.select(current);

    this._open = false;
    this._edit = false;
  }


  /*Functions*/
  private init() {

    //Current
    if (this.model != null) {
      this.model = new Date(this.model);
      this._currentYear = this.model.getFullYear();
      this._currentMonth = this.model.getMonth();
      this._currentDay = this.model.getDate();

      this.write();
    }
    else {
      let current = new Date();
      this._currentYear = current.getFullYear();
      this._currentMonth = current.getMonth();
      this._currentDay = current.getDate();

      this.write(true);
    }
  }
  private write(empty:boolean = false) {

    this._openMonth = false;
    this._openYear = false;

    let current: Date;
    if (empty) {
      this.model = null;
      this._formatDate = '-- --- ----';
      current = new Date();
    }
    else {
      if (this.model != null) {
        this.model = new Date(this._currentYear, this._currentMonth, this._currentDay, this.model.getHours(), this.model.getMinutes(), this.model.getSeconds());
      }
      else {
        this.model = new Date(this._currentYear, this._currentMonth, this._currentDay);
      }

      if (this.dateTimeService.isValid(this.model)) {

        if (this.model.getMonth() != this._currentMonth) {
          while (this.model.getMonth() != this._currentMonth) {
            this.model = this.dateTimeService.addDays(this.model, -1);
          }
          this._currentDay = this.model.getDate();
        }

        let datewithoutmonth = this.dateTimeService.format(this.model, 'dd {0} yyyy');
        this._formatDate = datewithoutmonth.replace('{0}', this.dateTimeService.monthname(this.model, 3));

        current = this.model;
      }
    }

    //First monday date
    let first = this.dateTimeService.firstDayInMonth(current);
    while (first.getDay() != 1) {
      first = new Date(first.getTime() - this.dateTimeService.oneday);
    }

    //Last sunday date
    let last = this.dateTimeService.lastDayInMonth(current);
    while (last.getDay() != 0) {
      last = new Date(last.getTime() + this.dateTimeService.oneday);
    }

    this._days = [,];
    while (first < last) {

      var cols = [];
      for (var i = 0; i < 7; i++) {
        var d = new Date(first.getTime() + (i * this.dateTimeService.oneday));
        cols.push({
          date: d,
          current: d.getFullYear() == this._currentYear && d.getMonth() == this._currentMonth && d.getDate() == this._currentDay,
          outside: d.getMonth() != this._currentMonth
        });
      }

      this._days.push(cols);

      var diff = (new Date(first.getTime() + (6 * this.dateTimeService.oneday)).getTimezoneOffset() - new Date(first.getTime() + (7 * this.dateTimeService.oneday)).getTimezoneOffset()) * 60 * 1000;
      first = new Date(first.getTime() + (7 * this.dateTimeService.oneday) - diff);
    }

  }

  private setFocus() {

    if (this.input) {
      setTimeout(() => {
        let element = this.input.nativeElement;
        element.focus();
        if (element.select) {
          element.select();
        }
      }, 0); //Create a macrotask that will run in the next VM turn
    }
  }

  private specialDateTimeFormat() {

    let date = null;
    
    if (this._manualDate) {
      if (!isNaN(parseInt(this._manualDate))) {
        if (this._manualDate.length == 8) {
          date = new Date(parseInt(this._manualDate.substr(0, 4)), parseInt(this._manualDate.substr(4, 2))-1, parseInt(this._manualDate.substr(6, 2)));
        }
        else if (this._manualDate.length == 6) {
          date = new Date(parseInt(this._manualDate.substr(0, 2)) + 2000, parseInt(this._manualDate.substr(2, 2))-1, parseInt(this._manualDate.substr(4, 2)));
        }
      }
    }

    return date;
  }

}
