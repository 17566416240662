import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';


@Component({
  selector: 'swe-messagedata',
  templateUrl: './messagedata.component.html'
})
export class MessageDataComponent implements OnInit {

  @Input() id: number = 0;
  @Input() category: string = '';

  private _message: any = {};
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private router: Router
  ) {

    
  }

  ngOnInit() {
    this.load();
  }

  /*Properties*/
  public get message() {
    return this._message;
  }
  public get loading() {
    return this._loading;
  }



  //Methods



  //Functions
  private load() {

    this._loading = true;

    
    this.dataService.tokenRequest('/api/v1/messages/' + this.category + '/' + this.id, 'GET', {})
      .subscribe(res => {
        if (res) {

          this._message = res;

          this._message.FullAddress = this._message.From;
          if (this._message.DisplayName != null && this._message.DisplayName.length > 0) {
            this._message.FullAddress += ' (' + this._message.DisplayName + ')';
          }

          this._message.AttachmentList = [];
          this._message.Attachments.forEach((attachment) => {
            let attach = attachment.split('|');
            if (attach.length == 2) {
              this._message.AttachmentList.push({ Original: attach[0], Unique: attach[1] });
            }
          });

          this._message.Bookings.forEach((booking) => {
            booking.datetimeheader = this.dateTimeService.header(new Date(booking.Start), new Date(booking.End), false);
          });

          this._loading = false;
        }
      });
  }
  public download(attachment) {

    let filename = attachment.Original;

    this.dataService.imageRequest('/api/v1/files/MailAttachments/' + attachment.Unique)
      .subscribe(res => {

        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = (e) => {

          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          let hiddenElement = document.createElement('a');
          //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
          //  //IE
          //  navigator.msSaveOrOpenBlob(res, filename);
          //}
          //else
          if (typeof hiddenElement.download != 'undefined' && !isSafari) {
            //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
            hiddenElement.href = reader.result.toString();
            hiddenElement.target = '_blank';
            hiddenElement.download = filename;

            document.body.appendChild(hiddenElement);

            hiddenElement.click();
          }
          else {
            let url = '';
            try {
              let fileArr = reader.result.toString().split(';');
              let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

              url = URL.createObjectURL(blob);
            }
            catch (e) {
              //Final solution
              let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
              url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
            }

            var popup = window.open(url, '_blank');
            if (!popup) {
              window.location.href = url;
            }
          }
        }

      });
  }
  public gotoShift(id, e) {

    e.stopPropagation();

    let url = '/bookings/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public resend() {
    let url = '/messages/send/';
    if (this._message.IsEmail) {
      url += 'email/';
    }
    else if (this._message.IsSms) {
      url += 'sms/';
    }

    url += 'resend/';
    url += this._message.Id;

    this.router.navigate([url]);
  }

}
