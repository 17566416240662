import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';
import { Tab } from '../layout/card.component';





@Component({
  selector: 'swe-generalarticles',
  templateUrl: './generalarticles.component.html'
})
export class GeneralArticlesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: number = 0;
  @Input() header: string = '';
  @Input() open: boolean;
  @Input() cardcolor: string;
  @Input() idList: any[] = [];
  @Input() isoverview: boolean = false;
  @Input() isuser: boolean = false;
  @Input() islevel: boolean = false;
  @Input() salaryaccess: number = 0;
  @Input() invoiceaccess: number = 0;

  private _tabs: Tab[] = [];
  private _activetab = 0;
  private _subscriptions: Subscription[] = [];
  private _groupedby: any = {};
  private _articles: any[] = [];
  private _loading: boolean = false;
  private _sumarticles: sumarticles[] = [];
  private _sumsalary: number = 0;
  private _suminvoice: number = 0;
  private _sumtb: number = 0;
  private _sumtg: number = 0;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private router: Router
  ) {
    this._subscriptions.push(settingService.onViewRefresh$
      .subscribe((refresh) => {

        if (refresh.type == 'reload_articles') {
          if (this.open) {
            this.load();
          }
        }

      }));

    this._subscriptions.push(settingService.onRefresh$
      .subscribe(() => {
        this.load();
      }));
  }


  ngOnInit() {

    this._tabs.push({ 'id': 0, 'name': this.languageService.getItem(1234) }); //Articles
    if (this.isoverview) {
      this._tabs.push({ 'id': 1, 'name': this.languageService.getItem(3) }); //Levels
      this._tabs.push({ 'id': 2, 'name': this.languageService.getItem(2) }); //Users
    }
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


  //Properties
  public get tabs() {
    return this._tabs;
  }
  public get activetab() {
    return this._activetab;
  }
  public get groupedby() {
    return this._groupedby;
  }
  public get articles() {
    return this._articles;
  }
  public get isloading() {
    return this._loading;
  }
  public get sumarticles() {
    return this._sumarticles;
  }
  public get sumsalary() {
    return this._sumsalary;
  }
  public get suminvoice() {
    return this._suminvoice;
  }
  public get sumtb() {
    return this._sumtb;
  }
  public get sumtg() {
    return this._sumtg;
  }
  


  //Methods
  public loadByTab(e) {

    this._activetab = e;
    this.load();
    
  }
  public load() {
    
    this._loading = true;

    let url = '/api/v1/timereports/' + this.id + '/articles/';
    let verb = 'Get';
    let dto = {};
    if (this.isoverview) {
      //Overview
      url = '/api/v1/articles/search/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        Type: this._activetab,
        ArticleType: this.settingService.article.type,
        Articles: this.settingService.article.articles
      };

      //User
      dto['UserFrameworkContracts'] = this.settingService.user.frameworkcontracts;
      dto['UserContract'] = this.settingService.user.contract;
      dto['UserContracts'] = this.settingService.user.contracts;
      dto['UserLevelGroups'] = this.settingService.user.levelgroups.join();
      dto['UserLevelList'] = this.settingService.levelList(1);

      //Levels
      dto['LevelFrameworkContracts'] = this.settingService.level.frameworkcontracts;
      dto['LevelContract'] = this.settingService.level.contract;
      dto['LevelContracts'] = this.settingService.level.contracts;
      dto['LevelGroups'] = this.settingService.level.levelgroups.join();
      dto['LevelList'] = this.settingService.levelList(3);
    }
    else if (this.isuser) {
      //User
      url = '/api/v1/users/' + this.id + '/articles/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        IdList: this.idList //TimereportIdList
      };
    }
    else if (this.islevel) {
      //User
      url = '/api/v1/levels/' + this.id + '/articles/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        IdList: this.idList //BookingIdList
      };
    }

    //Reset
    this._sumarticles = [];
    this._sumsalary = 0;
    this._suminvoice = 0;
    this._sumtb = 0;
    this._sumtg = 0;

    this.dataService.tokenRequest(url, verb, dto)
      .subscribe((res) => {

        this._articles = res;

        let groupedbyresource: any[] = [];
        if (this._activetab == 0) {
          groupedbyresource.push(this._articles);
        }
        else {
          this.listService.sort(this._articles, this._activetab == 1 ? ['LevelName', 'LevelId'] : ['Fullname', 'UserId'], true);

          let lastId = 0;
          let key = this._activetab == 1 ? 'LevelId' : 'UserId';
          this._articles.forEach((article) => {
            if (lastId != article[key]) {
              groupedbyresource.push([]);
            }

            groupedbyresource[groupedbyresource.length - 1].push(article);

            lastId = article[key];
          });
        }

        groupedbyresource.forEach((articlelist) => {

          let totalsalary = 0;
          let totalinvoice = 0;
          articlelist.forEach((article) => {

            article = this.manageArticle(article);

            article.UnderlyingList.forEach((underlying) => {

              underlying = this.manageArticle(underlying);

            });

            if (article.Type == 1) {
              //Salary
              totalsalary += article.Total;
            }
            else {
              //Invoice
              totalinvoice += article.Sum;
            }

          });


          let name = '';
          if (this._activetab == 1) {
            name = articlelist[0].LevelName; 
          }
          else if (this._activetab == 2) {
            name = articlelist[0].Fullname;
          }

          let salary = this.generalService.formatdecimal(totalsalary);
          let invoice = this.generalService.formatdecimal(totalinvoice);
          let tb = this.generalService.formatdecimal(invoice - salary);
          let tg = (invoice > 0) ? this.generalService.formatdecimal((tb * 100) / invoice) : 0;

          //Per row
          this._sumarticles.push({
            name: name,
            salary: salary,
            invoice: invoice,
            tb: tb,
            tg: tg
          });

          //Total
          this._sumsalary += salary;
          this._suminvoice += invoice;
          this._sumtb += tb;
          //You can't sum tg => count when all is ready below
          //this._sumtg += tg;
        });

        //Format
        this._sumsalary = this.generalService.formatdecimal(this._sumsalary);
        this._suminvoice = this.generalService.formatdecimal(this._suminvoice);
        this._sumtb = this.generalService.formatdecimal(this._sumtb);
        this._sumtg = (this._suminvoice > 0) ? this.generalService.formatdecimal((this._sumtb * 100) / this._suminvoice) : 0;

        this._groupedby = this.listService.groupByInt(
          this._articles,
          'Type',
          [
            { Id: 1, Name: this.languageService.getItem(1004), Sort: 0 },
            { Id: 2, Name: this.languageService.getItem(1005), Sort: 1 }
          ]);

        this._loading = false;
      });
    

  }
  private loadGroupByArticles() {

  }
  private manageArticle(article) {
    article.Sum = article.Quantity * article.Price;
    if (article.Type == 1) {
      //Salary
      article.ExtraEmployerFee = 0;
      if (article.EmployerFee > 0) {
        article.ExtraEmployerFee = article.Sum * article.EmployerFee / 100;
      }
      article.ExtraVacationPercentage = 0;
      if (article.VacationPercentage > 0) {
        article.ExtraVacationPercentage = article.Sum * article.VacationPercentage / 100;
      }
      article.ExtraPensionPercentage = 0;
      if (article.PensionPercentage > 0) {
        article.ExtraPensionPercentage = article.Sum * article.PensionPercentage / 100;
      }
      article.ExtraGeneralPercentage = 0;
      if (article.GeneralPercentage > 0) {
        article.ExtraGeneralPercentage = article.Sum * article.GeneralPercentage / 100;
      }
      article.ExtraCost = 0;
      if (article.Cost > 0) {
        article.ExtraCost = article.Sum * article.Cost / 100;
      }
      article.Total = article.Sum + article.ExtraCost;
    }
    else {
      //Invoice
      article.Total = article.Sum
    }
    article.Quantity = this.generalService.formatdecimal(article.Quantity);
    article.Price = this.generalService.formatdecimal(article.Price);
    article.Sum = this.generalService.formatdecimal(article.Sum);
    article.ExtraEmployerFee = this.generalService.formatdecimal(article.ExtraEmployerFee);
    article.ExtraVacationPercentage = this.generalService.formatdecimal(article.ExtraVacationPercentage);
    article.ExtraPensionPercentage = this.generalService.formatdecimal(article.ExtraPensionPercentage);
    article.ExtraGeneralPercentage = this.generalService.formatdecimal(article.ExtraGeneralPercentage);
    article.ExtraCost = this.generalService.formatdecimal(article.ExtraCost);
    article.Total = this.generalService.formatdecimal(article.Total);
    
    return article;
  }
  public goto(id, e) {

    e.stopPropagation();

    let url = '/admin/articles/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }



  //Functions
  
}

export class sumarticles {
  name: string;
  salary: number;
  invoice: number;
  tb: number;
  tg: number;
}
