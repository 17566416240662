import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';


@Component({
  selector: 'app-externalproviders',
  templateUrl: './externalproviders.component.html'
})
export class ExternalProvidersComponent implements OnInit {

  private _tabs: any[] = [];
  private _providers: any[] = [];
  private _provider: any = {};
  private _groupbyproperties: any[] = [];
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private alertService: AlertService,
    private listService: ListService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.init();
    this.load();

  }



  //Properties
  public get tabs() {
    return this._tabs;
  }
  public get provider() {
    return this._provider;
  }
  public get groupbyproperties() {
    return this._groupbyproperties;
  }
  public get isloading() {
    return this._loading;
  }
  




  //Methods
  public init() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/externalproviders', 'GET', {})
      .subscribe((res) => {

        this._providers = res;

        this._providers.forEach((provider) => {
          this._tabs.push({ 'id': provider.Id, 'name': provider.Name });
        });

        if (this._providers.length > 0) {
          this.changeProvider(this._providers[0].Id);
        }

        this._loading = false;
      });
  }
  public load() {
    if (this._provider) {
      this.changeProvider(this._provider.Id);
    }
  }
  public change(e) {
    this.changeProvider(e);
  }
  public save() {
    this.dataService.tokenRequest('/api/v1/externalproviders', 'POST', this._provider, 'text', 'response')
      .subscribe(response => {

        this.alertService.Add({ type: 'success', message: response.body });

        this.load();
      });
  }
  


  //Functions
  private changeProvider(id) {

    this._provider = {};
    this._loading = true;

    this.dataService.tokenRequest('/api/v1/externalproviders/' + id, 'GET', {})
      .subscribe((res) => {

        this._provider = res;

        this._groupbyproperties = [];

        let groupby = this.listService.groupByInt(this._provider.Properties, 'GroupBy', null, 'GroupBy');
        Object.entries(groupby).forEach(([key, value]) => {
          this._groupbyproperties.push({ 'Name': key, Items: value['items'] });
        });


        this._loading = false;
      });

  }
}
