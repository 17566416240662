import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'swe-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {

  @Input() isOpen: boolean;
  @Input() icon: string;
  @Input() buttonclass: string = 'btn-swe';
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() loading: boolean;
  @Input() collapsed: boolean;
  @Output() isOpenChange = new EventEmitter<any>()
  @Input() showLabel: boolean = false;
  @Input() stopPropagation: boolean = true;


  constructor() {
    
  }

  public close(event) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }
  public buttonClick(event) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
}
}
