<swe-pageheader [header]="languageService.getItem(345)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [extra]="extra" [extraPermissions]="permissionService.permissions.Email>0||permissionService.permissions.Sms>0" [datePagerOpt]="{ type:'message', hideTime: false }" [all]="all" (searchEvent)="handleSearchEvent($event)">
  <ng-template #extra>
    <div class="bi-swe-pull-right d-lg-block d-none mb-2">
      <div class="position-absolute" style="width:108px;right:30px;">

        <swe-dropdown icon="bi-plus-lg" [showLabel]="true" [(isOpen)]="addMenu" [buttonclass]="'btn-success'" [label]="languageService.getItem(100)">
          <ul class="dropdown-menu" [ngClass]="{'show': addMenu }">
            <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0" (click)="send(1, $event)">
              <i class="bi bi-swe-fw bi-envelope-fill" [attr.title]="languageService.getItem(321)"></i>
              {{languageService.getItem(321)}}
            </li>
            <li class="dropdown-item swe-click my-1" *ngIf="permissionService.permissions.Sms>0" (click)="send(3, $event)">
              <i class="bi bi-swe-fw bi-phone-fill" [attr.title]="languageService.getItem(320)"></i>
              {{languageService.getItem(320)}}
            </li>
          </ul>
        </swe-dropdown>
      </div>
    </div>
  </ng-template>
</swe-fixedheader>
<div class="row py-2">
  <div class="col-12 d-none d-md-block" *ngIf="permissionService.permissions.HasSmsStatistics">
    <swe-smsstatistics [start]="start" [end]="end"></swe-smsstatistics>
  </div>
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search()" toolbarClass="mt-1">
      <swe-list [data]="messages" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let message of messages" (click)="open(message.Id, message.Category, $event)" [ngClass]="{'list-group-item-danger':message.StatusCode == 4}">
          <div class="col-12 col-md-2">
            {{dateTimeService.format(message.SendTime, 'yyyy-MM-dd HH:mm:ss')}}
            <span class="small ms-2" *ngIf="message.SendDiff">({{dateTimeService.format(message.CreatedTime, 'yyyy-MM-dd HH:mm:ss')}})</span>
          </div>
          <div class="col-12 col-md-2">{{message.From}}</div>
          <div class="col-12 col-md-1">{{message.DisplayName}}</div>
          <div class="col-12 col-md-2">{{message.To}}</div>
          <div class="col-12 col-md-1">{{message.DisplayNameTo}}</div>
          <div class="col-12 col-md-2" [attr.title]="message.Body"><span style="font-weight:bold;" *ngIf="message.Subject.length>0">{{message.Subject}} </span>{{message.Body.substring(0,50)}}<span *ngIf="message.Body.length>50">...</span></div>
          <div class="col-12 col-md-1">{{message.Status}}&nbsp;({{message.Type}})</div>
          <div class="col-12 col-md-1">
            <i class="bi bi-swe-fw bi-swe bi-paperclip" *ngIf="message.Attachments.length>0"></i>
            <i class="bi bi-swe-fw bi-swe bi-trash-fill" *ngIf="deleteAccess(message)" (click)="delete(message, $event)"></i>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
