<swe-pageheader [header]="languageService.getItem(4)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <a href="javascript:void(0)" class="swe-no-link" *ngIf="id>0&&permissionService.permissions.Levels>1" (click)="gotoLevel()" [attr.title]="languageService.getItem(1125)"><i class="bi bi-swe-fw bi-diagram-3-fill"></i></a>
    <button class="btn-success border-0 px-1 bi-swe-pull-right rounded text-white" (click)="create()" *ngIf="permissionService.permissions.Bookings>1&&id>0">
      <i class="bi bi-swe-fw bi-plus-lg swe-click"></i>
    </button>
  </span>
</swe-pageheader>
<div class="row d-flex d-lg-block py-2">
  <div class="col-12 col-lg-4 float-start order-lg-0" [ngClass]="{'order-1': !cards.data, 'order-0': cards.data}">
    <swe-bookingdata [id]="id" [(open)]="cards.data" [userid]="userid" [levelid]="levelid" [employmentplan]="employmentplan" (bookingdataChange)="manageBookingdata($event)"></swe-bookingdata>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.users, 'order-0': cards.users}" *ngIf="id>0">
    <swe-bookingusers [id]="id" [(open)]="cards.users" [bookingdata]="bookingdata"></swe-bookingusers>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.availableusers, 'order-0': cards.availableusers}" *ngIf="id>0&&bookingdata.UsersAccess>0">
    <swe-bookingavailableusers [id]="id" [(open)]="cards.availableusers" [bookingdata]="bookingdata"></swe-bookingavailableusers>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.requests, 'order-0': cards.requests}" *ngIf="id>0&&bookingdata.RequestAccess>0">
    <swe-generalrequests [bookingid]="id" [(open)]="cards.requests" [data]="bookingdata"></swe-generalrequests>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects; index as i">
    <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.objects || !cards.objects[i], 'order-0': cards.objects && cards.objects[i]}" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="!cards.objects ? false : cards.objects[i]" (openChange)="!cards.objects ? false : cards.objects[i] = $event"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.logs, 'order-0': cards.logs}" *ngIf="id>0&&bookingdata.LogAccess>0">
    <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false" icon="bi-clock-history">
      <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'booking'" [data]="bookingdata"></swe-generallogs>
      <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'booking'" [data]="bookingdata"></swe-generalmessages>
    </swe-card>
  </div>
</div>
<div class="d-md-none d-inline-block">
  <app-botmenu [actions]="tabs" *ngIf="bookingdata.Id">
  </app-botmenu>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
