import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';


import { DataService } from '../_services/data.service';
import { TokenService } from '../_services/token.service';
import { AlertService } from '../_services/alert.service';
import { PermissionService } from './permission.service';
import { tap } from 'rxjs/operators';
import { GeneralService } from './general.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  //Events
  public onLoginChange$ = new EventEmitter<any>();

  /*Constructors*/
  constructor(
    private dataService: DataService,
    private tokenService: TokenService,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute) {
    
  }



  /*Properties*/
  public get isAuth() : boolean {
    let isauth = localStorage.getItem('isauth');

    return (typeof isauth != 'undefined' && isauth != null && isauth.toLowerCase() == 'true');
  }
  


  /*Methods*/
  public login(username: string, password: string): Observable<any> {

    return this.dataService.basicRequest('/api/v1/security/login', 'POST', { username, password }).pipe(
      tap(res => {
        if (res) {
          this.tokenService.saveTokens(res);
          this.onLoginChange$.emit(true);

          let reloadObj = 0;
          this.permissionService.reload().subscribe((res) => {
            reloadObj++;
            if (reloadObj == 2) {
              this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
            }
          });
        }
      })
    );
  }

  public logout() {

    if (this.isAuth) {
      this.dataService.tokenRequest('/api/v1/security/logout', 'Post', {})
        .subscribe((res) => {

          this.onLoginChange$.emit(false);

          this.tokenService.clearTokens();
          this.generalService.clearReports();
        });
    }
  }

  public redirectToLogin() {

    this.onLoginChange$.emit(false);

    this.tokenService.clearTokens();
    this.router.navigateByUrl('/login?logout=1');
  }

  public useRefreshToken(): Observable<any> {

    let data = JSON.stringify(localStorage.getItem('refreshtoken'));

    return this.dataService.basicRequest('/api/v1/security/refreshtoken', 'POST', data);

  }


  //SSO
  public loginsso(id) {

    window.open('/api/v1/security/sso/' + id, '_self');
  }
  public adminloginsso() {

    window.open('/api/v1/security/sso/admin', '_self');
  }


  //Token
  public otp(token) {
    this.tokenService.clearTokens();

    this.tokenService.setToken('otptoken', token);
  }
  public verify(code): Observable<any> {
    let parameters = {
    Token: this.tokenService.getToken('otptoken'),
    Code: code
    };

    return this.dataService.basicRequest('/api/v1/security/verify', 'POST', parameters).pipe(
      tap((data) => {

        if (data) {
          //Success login

          this.tokenService.saveTokens(data);
          this.onLoginChange$.emit(true);

          let reloadObj = 0;
          this.permissionService.reload().subscribe((res) => {
            reloadObj++;
            if (reloadObj == 2) {
              this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
            }
          });
        }
      })
    );
  }
  public tokensignin(token, provider): Observable<any> {

    let parameters = {
      Token: token,
      Provider: provider
    };

    this.alertService.ReActivate();
    
    return this.dataService.basicRequest('/api/v1/security/tokensignin', 'POST', parameters).pipe(
      tap((data) => {

        if (data) {
          //Success login

          this.tokenService.saveTokens(data);
          this.onLoginChange$.emit(true);

          let reloadObj = 0;
          this.permissionService.reload().subscribe((res) => {
            reloadObj++;
            if (reloadObj == 2) {
              this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
            }
          });
        }
      })
    );
  }


  //Lost Password
  public lostpasswordsend(address: string): Observable<any> {
    let parameters = {
      Address: address
    };

    return this.dataService.basicRequest('/api/v1/security/lostpassword/', 'POST', parameters, 'text');
  }
  public lostpasswordchange(key: string, password: string) {
    let parameters = {
      Key: key,
      Password: password
    };

    return this.dataService.basicRequest('/api/v1/security/lostpassword/reset', 'Post', parameters, 'text').pipe(
      tap((data) => {

        if (data) {
          //Success login

          this.tokenService.saveTokens(data);

          let reloadObj = 0;
          this.permissionService.reload().subscribe((res) => {
            reloadObj++;
            if (reloadObj == 2) {
              this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
            }
          });
        }
      })
    );

  }


  //Impersonate
  public impersonate(id, pincode) {
    let parameters = {
      AuthToken: this.tokenService.authBearer,
      Code: pincode
    };

    return this.dataService.basicRequest('/api/v1/security/impersonate/' + id, 'POST', parameters).pipe(
      tap((data) => {

        if (data) {
          //Success login

          this.tokenService.saveTokens(data);
          this.onLoginChange$.emit(true);

          this.generalService.reloadRoles().subscribe();
          this.generalService.reloadLevelGroups().subscribe();
          this.generalService.reloadMarkedDateTypes().subscribe();
          this.generalService.reloadReports().subscribe();
          this.permissionService.reload().subscribe();
        }

      })
    );
  }
}
